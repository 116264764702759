import { BehaviorSubject } from 'rxjs';

import { handleResponse } from '../helpers';
import { post, get } from '../utils';

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('currentUser'))
);

const login = (values) => {
  return post('/authenticate', values)
    .then(handleResponse)
    .then((user) => {
      localStorage.setItem('currentUser', JSON.stringify(user));
      currentUserSubject.next(user);
      return user;
    })
    .then((user) =>
      get('/me/permissions')
        .then(handleResponse)
        .then((permissions) => {
          user.permissions = permissions.result;
          localStorage.setItem('currentUser', JSON.stringify(user));
          currentUserSubject.next(user);
          return user;
        })
    );
};

const logout = () => {
  localStorage.removeItem('currentUser');
  currentUserSubject.next(null);
};

const forgot = (email) => {
  return post('/auth/forgot', { email })
    .then(handleResponse)
    .then((res) => res);
};

const reset = (values) => {
  return post('/auth/reset', values)
    .then(handleResponse)
    .then((res) => res);
};

const authenticationService = {
  login,
  logout,
  forgot,
  reset,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

export default authenticationService;

import React from 'react'
import * as Yup from 'yup'

import Table from './components/Table'

const cols = [
  {
    id: 1,
    key: 'label',
    label: 'Titel',
    type: 'text',
    sortable: true,
    editable: true,
    filterable: true,
    display: true,
    identifier: true
  }
]

const validationSchema = Yup.object().shape({
  label: Yup.string().required('Ange titel')
})

const Statuses = ({
  match, 
  history,
  location
}) => { 
  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={cols}
      title='Statusar'
      model='statuses'
      label='Status'
      hideActivities={true}
      validationSchema={validationSchema}
    />
  )
}
 export default Statuses

import React, {useCallback, useState, useEffect, useRef} from 'react'
import { useReactToPrint } from 'react-to-print'
import classnames from 'clsx'
import * as Yup from 'yup'
import { Prompt } from 'react-router-dom'
import {useDropzone} from 'react-dropzone'
import xlsx from 'xlsx'
import { Form, Formik, FieldArray, Field, getIn } from 'formik'
import { useSnackbar } from 'notistack'

import { makeStyles, lighten } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Fab from '@material-ui/core/Fab'
import SaveIcon from '@material-ui/icons/Save'
import AddIcon from '@material-ui/icons/PlaylistAdd'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import ImportIcon from '@material-ui/icons/GetApp'
import CircularProgress from '@material-ui/core/CircularProgress'
import Drawer from '@material-ui/core/Drawer'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import { FormHelperText, FormControl } from '@material-ui/core'

import Skeleton from '@material-ui/lab/Skeleton'

import Excel from './images/excel'
import TextField from './components/fields/TextField'
import CustomSnackbar from './components/CustomSnackbar'
import ReactSelect from './components/fields/ReactSelect'
import AsyncSelect from './components/fields/AsyncSelect'
import Button from './components/Button'
import SplitButton from './components/SplitButton'

import { dataService } from './services'
import { InputAdornment } from '@material-ui/core'
import BarcodeIcon from './components/icons/BarcodeIcon'
import BarcodeField from './components/fields/BarcodeField'

import ArticlePrint from './ArticlePrint'

const useStyles = makeStyles(theme =>({
  paper: {
    width: '100%',
    marginBottom: 70
  },
  drawer: {
    width: 500,
    maxWidth: '80%'
  },
  wrapper: {
    width: '100%',
    padding: 24
  },
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20
  },
  fabIcon: {
    marginRight: 10
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    zIndex:1,
    top:0,
    left: 0,
    right: 0
  },
  highlight: {
    backgroundColor: lighten(theme.palette.primary.main, 1),
    color: theme.palette.primary.main
  }
}))


const ErrorMessage = ({ name, value }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name)
      const touch = getIn(form.touched, name);
      return touch && error ? <FormControl error><FormHelperText>{error}</FormHelperText></FormControl> : null;
    }}
  />
)

const Import = () => {
  useEffect(() => {
    dataService.getAll({model: 'articles', params: '?pagination=off'})
      .then(data => {
        const { result } = data
        const tmpOptions = []
        result.forEach(option => {
          tmpOptions.push({ value: option.id, label: option.label })
        })
        setOptions(tmpOptions)
        dataService.getAll({model: 'storages', params: '?pagination=off'})
          .then(data => {
            const { result } = data
            const tmpStorages = []
            result.forEach(storage => {
              tmpStorages.push({ value: storage.id, label: storage.label })
            })
            setStorages(tmpStorages)
            dataService.getAll({model: 'statuses', params: '?pagination=off'})
              .then(data => {
                const { result } = data
                const tmpStatuses = []
                result.forEach(status => {
                  tmpStatuses.push({ value: status.id, label: status.label})
                })
                setStatuses(tmpStatuses)
              })
          })
      })

    return () => {
      closeSnackbar()
    }
  }, [])

  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [articles, setArticles] = useState([])
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [storages, setStorages] = useState([])
  const [statuses, setStatuses] = useState([])
  const [selected, setSelected] = useState([])
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [current, setCurrent] = useState({})
  const [scannerMode, setScannerMode] = useState(true)
  const [moved, setMoved] = useState([])

  const inputRef = useRef(null)
  const printRef = useRef()

  const [validationSchema] = useState(Yup.object().shape({
    articles: Yup.array()
      .of(
        Yup.object().shape({
          article_id: Yup.number().nullable().required('Ange artikel'),
          series_number: Yup.string().nullable()
            .when('manufacturer_number', {
              is: value => !Boolean(value),
              then: Yup.string().required('Ange mätarnr eller tillverkningsnr')
            }),
        })
      )
  }))

  const getItem = (option, arrayHelpers, field = 'series_number') => {
    if (typeof option !== 'string' || option.trim().length < 2) {
      return
    }

    dataService.find({model: 'article_items', values: {[field]: [option] } })
      .then(
        data => {
          const { result } = data
          if (result && result.length > 0) {
            const newArticles = [...articles, data.result[0]]
            setArticles(newArticles)
            arrayHelpers.push({ article_id: '', series_number: '', manufacturer_number: '', unknown: true })
            inputRef.current.focus()
          } else {
            enqueueSnackbar(`${option} finns inte i systemet`, {variant: 'error'})
          }
        }
      )
  }

  const findItems = useCallback(async (serialNumbers, manufacturerNumbers) => {
    let foundItems = []

    if (serialNumbers.length > 0) {
      try {
        const response = await dataService.find({
          model: 'article_items', values: { series_number: serialNumbers } })
        const { result, excluded } = response
        foundItems = foundItems.concat(result)
        excluded.forEach(nr => {
          enqueueSnackbar(`${nr} hittades inte`, { variant: 'error' })
        })
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' })
      }
    }

    if (manufacturerNumbers.length > 0) {
      try {
        const response = await dataService.find({ model: 'article_items', values: { manufacturer_number: manufacturerNumbers } })
        const { result, excluded } = response
        foundItems = foundItems.concat(result.filter(res => !foundItems.find(found => found.id === res.id)))
        excluded.forEach(nr => {
          enqueueSnackbar(`${nr} hittades inte`, { variant: 'error' })
        })
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' })
      }
    }
    return foundItems
  }, [enqueueSnackbar])

  const onDropAccepted = useCallback(acceptedFiles => {
    setLoading(true)
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onabort = () => setLoading(false)
    reader.onerror = () => setLoading(false)
    reader.onload = e => {
      const bstr = e.target.result
      const wb = xlsx.read(bstr, {type:rABS ? 'binary' : 'array'})
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      const data = xlsx.utils.sheet_to_json(ws, {header:1})
      setLoading(false)
      const serialArray = []
      const manufacturerArray = []
      data.forEach(row => {
        const [sn, mn] = row
        if ((typeof sn === 'number' && sn > 0) || (typeof sn === 'string' && sn.length > 0)) {
          serialArray.push(sn)
        }
        if ((typeof mn === 'number' && mn > 0) || (typeof mn === 'string' && mn.length > 0)) {
          manufacturerArray.push(mn)
        }
      })

      findItems(serialArray, manufacturerArray)
        .then(
          data => {
            setArticles(data)
          },
          error => {
            enqueueSnackbar(error, { variant: 'error' })
          }
        )
    }
    acceptedFiles.forEach(file => rABS ? reader.readAsBinaryString(file) : reader.readAsArrayBuffer(file))
  }, [enqueueSnackbar, findItems])

  const onDropRejected = useCallback(() => {
    enqueueSnackbar('Filformatet stöds inte', {
      variant: 'error',
      style: {
        bottom: 90
      },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      }
    })
  }, [enqueueSnackbar])

  const handleMultiChange = option => {
    setDrawerOpen(!drawerOpen)
    setCurrent(option)
  }

  const isSelected = id => selected.indexOf(id) !== -1

  const handleSelectClick = (event, id) => {
    if (selected.indexOf(id) !== -1) {
      setSelected([...selected.slice(0, selected.indexOf(id)), ...selected.slice(selected.indexOf(id) + 1)])
    } else {
      setSelected([...selected, id])
    }
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = articles.map(d => d.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({onDropAccepted, onDropRejected, noClick: true, accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  })

  return (
    <React.Fragment>
      <Paper className={classes.paper} {...getRootProps()} style={{ opacity: isDragActive ? 0.4 : 1}}>
          <input {...getInputProps()} />
          <Toolbar className={classnames(classes.toolbar, {
            [classes.highlight]: selected.length > 0
          })}>
            {selected.length > 0 ? (
              <React.Fragment>
                <Typography variant='subtitle1'>
                  {selected.length} markerade
                </Typography>
                <SplitButton
                  options={[{key: 'storage_id', label: 'Lagerplats', options: storages }, {key: 'status_id', label: 'Status', options: statuses}]}
                  handleClick={option => handleMultiChange(option)}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography variant='h6'>
                  Lagerflytt
                </Typography>
                <IconButton onClick={open}>
                  <ImportIcon />
                </IconButton>
              </React.Fragment>
            )}
          </Toolbar>
          <Formik
            validationSchema={validationSchema}
            initialValues={{articles}}
            enableReinitialize
            onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
              const { articles } = values
              const parsedValues = articles.map(article => {
                const returnValue = {id: article.id, storage_id: article.storage_id, status_id: article.status_id }
                return returnValue
              })

              dataService.create({ values: parsedValues, model: 'article_items' })
                .then(
                  data => {
                    setSubmitting(false)
                    setArticles([])
                    setMoved(data)
                    resetForm()
                    enqueueSnackbar('', {
                      persist: true,
                      style: {
                        bottom: 90
                      },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                      },
                      children: (key) => (
                          <CustomSnackbar className={classes.test} id={key} header='Lagerflytt färdig' onPrint={handlePrint}/>
                      )
                    })
                  },
                  error => {
                    setSubmitting(false)
                    enqueueSnackbar(error, {variant: 'error'})
                    setStatus(error)
                  }
                )
            }}
          >
            {({
              values,
              errors,
              touched,
              status,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched
            }) => (
              <Form>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      {values.articles.length > 0 && (
                        <TableCell padding='checkbox'>
                          <Checkbox
                            indeterminate={selected.length > 0 && selected.length < values.articles.length}
                            color='primary'
                            checked={selected.length === values.articles.length}
                            onChange={handleSelectAllClick}
                            inputProps={{ 'aria-label': 'select all'}}
                          />
                        </TableCell>
                      )}
                      <TableCell>{values.articles.length > 0 && 'Artikel'}</TableCell>
                      <TableCell>{values.articles.length > 0 && 'Mätarnummer'}</TableCell>
                      <TableCell>{values.articles.length > 0 && 'Tillverkningsnummer'}</TableCell>
                      <TableCell>{values.articles.length > 0 && 'Lagerplats'}</TableCell>
                      <TableCell>{values.articles.length > 0 && 'Status'}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray
                      name='articles'
                      render={arrayHelpers => (
                        <React.Fragment>
                          {values.articles.length > 0 && values.articles.map((article, index) => 
                            {
                              const isItemSelected = isSelected(article.id)
                              const labelId = `table-checkbox-${article.id}`
                              return (
                                <TableRow key={index}>
                                  <TableCell padding='checkbox'>
                                    {loading ? (
                                      <Skeleton variant='react' />
                                    ) : (
                                      <Checkbox
                                        checked={isItemSelected}
                                        onChange={event => handleSelectClick(event, article.id)}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        color='primary'
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{ minWidth: 200}}
                                  >
                                    {loading ? (
                                        <Skeleton variant='rect' />
                                      ) : (
                                        <React.Fragment>
                                          <ReactSelect
                                            name={`articles[${index}][article_id]`}
                                            dense
                                            isDisabled={true}
                                            options={options}
                                            placeholder={`Välj artikel`}
                                            label={'Artikel'}
                                            value={options.find(option => option.value === values['articles'][index]['article_id'])}
                                            errors={errors}
                                            touched={touched}
                                          />
                                          <ErrorMessage name={`articles[${index}].article_id`} />
                                        </React.Fragment>
                                      )
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {loading ? (
                                        <Skeleton variant='rect' />
                                      ) : (
                                        <React.Fragment>
                                          {article.unknown ?
                                            (
                                              <Field
                                                name={`articles[${index}][series_number]`}
                                                dense
                                                variant='outlined'
                                                margin='dense'
                                                fullWidth
                                                onComplete={scannerMode ? data => getItem(data, arrayHelpers) : undefined}
                                                InputProps={{
                                                  inputProps: {
                                                      ref: inputRef,
                                                      onBlur: !scannerMode ? () => getItem(values['articles'][index]['series_number'], arrayHelpers) : undefined
                                                  },
                                                  endAdornment: (
                                                    <InputAdornment position='end'>
                                                      <IconButton
                                                        onClick={() => setScannerMode(!scannerMode)}
                                                      >
                                                        {scannerMode ? (
                                                          <BarcodeIcon />
                                                        ) : (
                                                          <KeyboardIcon />
                                                        )}
                                                      </IconButton>
                                                    </InputAdornment>
                                                  )
                                                }}
                                                component={scannerMode ? BarcodeField : TextField}
                                              />
                                            )
                                          : (
                                            <Field
                                              variant='outlined'
                                              margin='dense'
                                              type='text'
                                              disabled
                                              name={`articles[${index}][series_number]`}
                                              component={TextField}
                                            />
                                          )}
                                        </React.Fragment>
                                      )
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {loading ? (
                                        <Skeleton variant='rect' />
                                      ) : (
                                        <React.Fragment>
                                          {article.unknown ?
                                            (
                                              <Field
                                                name={`articles[${index}][manufacturer_number]`}
                                                dense
                                                variant='outlined'
                                                margin='dense'
                                                fullWidth
                                                onComplete={scannerMode ? data => getItem(data, arrayHelpers, 'manufacturer_number') : undefined}
                                                InputProps={{
                                                  inputProps: {
                                                      ref: inputRef,
                                                      onBlur: !scannerMode ? () => getItem(values['articles'][index]['manufacturer_number'], arrayHelpers, 'manufacturer_number') : undefined
                                                  },
                                                  endAdornment: (
                                                    <InputAdornment position='end'>
                                                      <IconButton
                                                        onClick={() => setScannerMode(!scannerMode)}
                                                      >
                                                        {scannerMode ? (
                                                          <BarcodeIcon />
                                                        ) : (
                                                          <KeyboardIcon />
                                                        )}
                                                      </IconButton>
                                                    </InputAdornment>
                                                  )
                                                }}
                                                component={scannerMode ? BarcodeField : TextField}
                                              />
                                            )
                                          : (
                                            <Field
                                              variant='outlined'
                                              margin='dense'
                                              type='text'
                                              disabled
                                              name={`articles[${index}][manufacturer_number]`}
                                              component={TextField}
                                            />
                                          )}
                                        </React.Fragment>
                                      )
                                    }
                                  </TableCell>
                                  <TableCell style={{ minWidth: 200}}>
                                    {loading ? (
                                        <Skeleton variant='rect' />
                                      ) : (
                                        <ReactSelect
                                          name={`articles[${index}][storage_id]`}
                                          dense
                                          options={storages}
                                          placeholder={`Välj lagerplats`}
                                          label={'Lagerplats'}
                                          onChange={option => setFieldValue(`articles[${index}][storage_id]`, option.value)}
                                          onBlur={() => setFieldTouched(`articles[${index}][storage_id]`, true)}
                                          value={storages.find(storage => storage.value === values['articles'][index]['storage_id'])}
                                          errors={errors}
                                          touched={touched}
                                        />
                                      )
                                    }
                                  </TableCell>
                                  <TableCell style={{ minWidth: 200}}>
                                    {loading ? (
                                        <Skeleton variant='rect' />
                                      ) : (
                                        <ReactSelect
                                          name={`articles[${index}][status_id]`}
                                          dense
                                          options={statuses}
                                          placeholder={`Välj status`}
                                          label={'Status'}
                                          onChange={option => setFieldValue(`articles[${index}][status_id]`, option.value)}
                                          onBlur={() => setFieldTouched(`articles[${index}][status_id]`, true)}
                                          value={statuses.find(status => status.value === values['articles'][index]['status_id'])}
                                          errors={errors}
                                          touched={touched}
                                        />
                                      )
                                    }
                                  </TableCell>
                                  <TableCell align='right'>
                                    {loading ? (
                                        <Skeleton variant='circle' width={43} height={43} style={{ display: 'inline-flex' }}/>
                                      ) : (
                                        <IconButton
                                          aria-label='delete'
                                          onClick={() => arrayHelpers.remove(index)}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      )
                                    }
                                  </TableCell>
                                </TableRow>
                              )
                            }
                          )}
                          {
                            values.articles <= 0 && (
                              <React.Fragment>
                                {loading ? (
                                  [...Array(10)].map((e, i) => (
                                    <TableRow key={i}>
                                      <TableCell>
                                        <Skeleton variant='rect' />
                                      </TableCell>
                                      <TableCell>
                                        <Skeleton variant='rect' />
                                      </TableCell>
                                      <TableCell>
                                        <Skeleton variant='rect' />
                                      </TableCell>
                                      <TableCell>
                                        <Skeleton variant='rect' />
                                      </TableCell>
                                      <TableCell>
                                        <Skeleton variant='rect' />
                                      </TableCell>
                                      <TableCell align='right'>
                                        <Skeleton variant='circle' width={43} height={43} style={{ display: 'inline-flex' }}/>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={7} style={{ padding: 50 }}>
                                      <Excel
                                        color={'#4daf50'}
                                        style={{
                                          marginLeft: 'auto',
                                          margin: 'auto',
                                          marginBottom: 30,
                                          display: 'flex'
                                        }}
                                      />
                                      <Typography component='h2' variant='h5' align='center'>
                                        Ladda upp fil (*.xlsx)
                                      </Typography>
                                      <Typography component='h3' variant='subtitle1' align='center' style={{marginTop: 10}}>
                                        Drag och släpp fil från dator eller 
                                        <Button
                                          onClick={open}
                                          style={{ margin: '0 0 0 10px'}}
                                          variant='contained'
                                          size='small'
                                          color='primary'
                                          label='Klicka här'
                                        />
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  )}
                              </React.Fragment>
                            )
                          }
                          <TableRow>
                            <TableCell padding='checkbox'/>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell align='right'>
                              <IconButton
                                  aria-label='add'
                                  onClick={() => arrayHelpers.push({ article_id: null, series_number: '', manufacturer_number: '', unknown: true })}
                                  color='primary'
                                >
                                  <AddIcon />
                                </IconButton>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )}
                    />
                  </TableBody>
                </Table>
                <Prompt
                  when={articles.length > 0}
                  message='Dina ändringar är inte sparade. Är du säker på att du vill gå vidare?'
                />
                <Fab
                  variant='extended'
                  aria-label='submit'
                  type='submit'
                  color='primary'
                  disabled={isSubmitting || values.articles.length < 1}
                  className={classes.fab}
                >
                  <SaveIcon className={classes.fabIcon} />
                  {isSubmitting && <CircularProgress style={{position: 'absolute', left: 5, color: '#fff'}} size={45} />}
                  Spara
                </Fab>
              </Form>
            )}
          </Formik>
          <Drawer
            classes={{
              paper: classes.drawer
            }}
            anchor='right'
            open={drawerOpen}
            onClose={() => handleMultiChange({})}
          >
            <Toolbar className={classes.toolbar}>
              <Typography variant='h6'>
                Ändra
              </Typography>
              <IconButton onClick={() => handleMultiChange({})}>
                <ClearIcon />
              </IconButton>
            </Toolbar>
            <div className={classes.wrapper}>
              <Formik
                onSubmit={(values, {setSubmitting, setStatus }) => {
                  const newArticles = articles.map((item) => {
                    if (selected.includes(item.id)) {
                      const returnValue = {...item, [current.key]: values[current.key]}
                      return returnValue
                    }
                    return item
                  })
                  // const bulk = articles.filter(item => selected.includes(item.id))
                  // const bulkData = bulk.map(item => {
                  //   const returnValue = {...item, [current.key]: values[current.key]}
                  //   return returnValue
                  // })
                  // const newArticles = [...articles.filter(item => !selected.includes(item.id)), ...bulkData]
                  setArticles(newArticles)
                  setSubmitting(false)
                  handleMultiChange({})
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  status,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched
                }) => (
                  <Form>
                      <ReactSelect
                        name={current.key}
                        options={current.options}
                        placeholder={`Välj ${current.label}`}
                        label={current.label}
                        onChange={sel => setFieldValue(current.key, sel.value)}
                        onBlur={() => setFieldTouched(current.key, true)}
                        errors={errors}
                        touched={touched}
                      />
                    <Button
                      type='submit'
                      size='large'
                      fullWidth
                      variant='contained'
                      disabled={isSubmitting}
                      color='primary'
                      label='Spara'
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </Drawer>
      </Paper>
      <div style={{ display: 'none' }}>
        <ArticlePrint ref={printRef} data={moved} title={'Lagerflytt'} />
      </div>
    </React.Fragment>
  )
}

export default Import

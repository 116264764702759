import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'

import {dataService} from './services' 
import Table from './components/Table'

const cols = [
  {
    id: 1,
    key: 'role_id',
    human: 'role',
    label: 'Roll',
    type: 'number',
    sortable: true,
    filterable: true,
    options: [],
    display: true
  },
  {
    id: 2,
    key: 'label',
    label: 'Titel',
    type: 'text',
    sortable: true,
    display: true
  },
  {
    id: 3,
    key: 'read',
    label: 'Läsa',
    boolean: true,
    editable: true,
    display: true
  },
  {
    id: 4,
    key: 'write',
    label: 'Skriva',
    boolean: true,
    editable: true,
    display: true
  },
  {
    id: 5,
    key: 'remove',
    label: 'Ta bort',
    boolean: true,
    editable: true,
    display: true
  }
]

const Permissions = ({
  match,
  history,
  location
}) => {
  useEffect(() => {
    dataService.getAll({model: 'roles', params: '?pagination=off'})
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label })
        })
        cols.find(col => col.id === 1).options = options
        setEnhanced(cols)
      })
  }, [])

  const [enhanced, setEnhanced] = useState(cols)

  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={cols}
      title='Behörigheter'
      model='permissions'
      label='Permission'
      locked
    />
  )
}

export default Permissions

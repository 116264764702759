import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MuiLink from '@material-ui/core/Link';

import { Link } from 'react-router-dom';
// import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import TextField from './components/fields/TextField';
import PasswordField from './components/fields/PasswordField';
import { authenticationService } from './services';
import Logo from './images/logo';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = ({ history, location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper}>
        <Logo size={90} />
        <Typography
          style={{ marginTop: 10 }}
          component="h1"
          variant="h4"
        >
          OneAsset
        </Typography>
        <Formik
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Felaktig e-postadress')
              .required('Ange e-postadress'),
            password: Yup.string().required('Du måste ange lösenord'),
          })}
          onSubmit={(values, { setSubmitting, setStatus }) => {
            authenticationService.login(values).then(
              (user) => {
                const { from } = location.state || {
                  from: { pathname: '/admin' },
                };
                history.push(from);
              },
              (error) => {
                setSubmitting(false);
                enqueueSnackbar(error, { variant: 'error' });
                // setStatus({msg: error})
              }
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            status,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form className={classes.form}>
              <Field
                variant="outlined"
                margin="normal"
                type="email"
                name="email"
                fullWidth
                autoFocus
                component={TextField}
                label="Användarnamn"
              />
              <Field
                variant="outlined"
                margin="normal"
                type="password"
                name="password"
                fullWidth
                component={PasswordField}
                label="Lösenord"
              />
              <div>{status && status.msg}</div>
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                disabled={isSubmitting}
                color="primary"
                className={classes.submit}
              >
                Logga in
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to={`/auth/forgot`}>
                    <MuiLink>Glömt lösenord?</MuiLink>
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default Login;

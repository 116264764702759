import React from 'react'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}))

export default ({ label, disabled, ...props }) => {
  const classes = useStyles()
  return (
    <Button
      disabled={disabled}
      className={classes.submit}
      {...props}
    >
      <span style={{opacity: disabled ? 0 : 1}}>{label}</span>
      {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
    </Button>
  )
}

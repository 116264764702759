import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { VictoryPie, VictoryTooltip } from 'victory'
import CountUp from 'react-countup'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

import HistoryIcon from '@material-ui/icons/History'
import NextIcon from '@material-ui/icons/NavigateNext'
import ArticlesIcon from '@material-ui/icons/Assignment'
import UsersIcon from '@material-ui/icons/Group'

import { authenticationService, dataService } from './services'

const useStyles = makeStyles(theme => ({
  header: {
    paddingBottom: 30
  },
  avatar: {
    backgroundColor: '#4daf50',
    marginLeft: 'auto'
  },
  button: {
    marginLeft: 'auto'
  },
  link: {
    textDecoration: 'none'
  },
  coloredPaper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}))

const Dashboard = () => {
  const classes = useStyles()

  useEffect(() => {
    dataService.getAll({model: 'article_items', params: ''})
      .then(data => {
        const {total} = data
        setArticles(total)
      })
      dataService.getAll({model: 'users', params: ''})
      .then(data => {
        const {total} = data
        setUsers(total)
      })
    // dataService.getAll({model: 'article_items', params: '?per_page=1000000000'})
    //   .then(data => {
    //     const { result } = data
    //     const test = {}
    //     const test2 = []
    //     result.forEach(item => {
    //       if (test[item.storage])
    //       {
    //         test[item.storage]['y'] = test[item.storage]['y'] + 1
    //         test[item.storage]['label'] = `${item.storage}(${test[item.storage]['y']})`
    //       } else {
    //         test[item.storage] = {x: item.storage, y: 1, label: `${item.storage}(1)` }
    //       }
    //     })
    //     Object.keys(test).forEach(storage => { if (storage !== 'null') test2.push(test[storage])})
    //     setStatistics(test2)
    //   })
    dataService.getAll({model: 'activities', params: '?per_page=5&order_by=created_at'})
      .then(data => {
        const { result } = data
        setActivities(result)
      })
  }, [])

  const [activities, setActivities] = useState([])
  const [articles, setArticles] = useState(0)
  const [users, setUsers] = useState(0)
  // const [statistics, setStatistics] = useState([])

  return (
    <React.Fragment>
      <Typography variant='h4' className={classes.header}>Välkommen, {authenticationService.currentUserValue && authenticationService.currentUserValue.name}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper>
            <Toolbar>
              <div style={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant='subtitle2' color='textSecondary'>Artiklar</Typography>
              <Typography variant='h5'><CountUp end={articles} separator=' ' /></Typography>
              </div>
              <Avatar style={{marginLeft: 'auto', backgroundColor: '#1a76d0'}}>
                <ArticlesIcon />
              </Avatar>
            </Toolbar>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper>
           <Toolbar>
              <div style={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant='subtitle2' color='textSecondary'>Användare</Typography>
              <Typography variant='h5'><CountUp end={users} separator=' '/></Typography>
              </div>
              <Avatar style={{marginLeft: 'auto', backgroundColor: '#d0911a'}}>
                <UsersIcon />
              </Avatar>
            </Toolbar>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} />
       {
        // <Grid item xs={12} sm={6} md={8}>
        //    <Paper style={{backgroundColor: '#1a76d0'}}>
        //      <div style={{maxWidth: '500px'}}>
        //      <VictoryPie
        //        labelComponent={<VictoryTooltip />}
        //        colorScale={['white']}
        //        data={statistics}
        //        innerRadius={100}
        //        padAngle={5}
        //        animate={{
        //          duration: 2000,
        //          onLoad: { duration: 1000 }
        //        }}
        //      />
        //      </div>
        //    </Paper>
        //  </Grid>
        }
        <Grid item xs={12} sm={6} md={8}>
          <Paper style={{height: '100%'}}/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper>
            <Toolbar>
              <Typography variant='h6'>Senaste händelser</Typography>
              <Avatar className={classes.avatar}>
                <HistoryIcon />
              </Avatar>
            </Toolbar>
            <List>
              {activities.map((activity, index) => (
                <React.Fragment key={activity.id}>
                  <Divider component='li' />
                  <ListItem key={activity.id}>
                    {/* <ListItemAvatar>
                      <Avatar>
                        H
                      </Avatar>
                    </ListItemAvatar> */}
                    <ListItemText primary={activity.human} secondary={moment(activity.created_at).format('YYYY-MM-DD HH:mm')} />
                  </ListItem>
                  {index === activities.length - 1 && <Divider component='li' />}
                </React.Fragment>
              ))}
            </List>
            <Toolbar>
              <Link
                to={'/admin/history'}
                className={classes.link}
              >
                <Button color='primary' className={classes.button}>
                  Visa alla <NextIcon />
                </Button>
              </Link>
            </Toolbar>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Paper className={classes.coloredPaper}>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}


export default Dashboard

import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'

import Table from './components/Table'
import { dataService } from './services'

const cols = [
  {
    id: 1,
    key: 'name',
    label: 'Namn',
    type: 'text',
    sortable: true,
    editable: true,
    filterable: true,
    display: true,
    identifier: true
  },
  {
    id: 2,
    key: 'email',
    label: 'Email',
    type: 'email',
    sortable: true,
    editable: true,
    filterable: true,
    display: true
  },
  {
    id: 3,
    key: 'role_id',
    label: 'Behörighet',
    human: 'role',
    sortable: true,
    editable: true,
    filterable: true,
    options: [],
    display: true
  },
  {
    id: 4,
    key: 'password',
    label: 'Lösenord',
    type: 'password',
    create: true,
    hidden: true
  }
]

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Ange namn'),
  email: Yup.string().email('Felaktig e-postadress').required('Ange e-postadress'),
  role_id: Yup.number().required('Ange roll')
})

const Users = ({
  match, 
  history,
  location
}) => { 

  useEffect(() => {
    dataService.getAll({ model: 'roles', params: '?pagination=off'})
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          if (option.label !== 'Super Admin') {
            options.push({ value: option.id, label: option.label })
          }
        })
        cols.find(col => col.id === 3).options = options
        setEnhanced(cols)
      })
  }, [])

  const [enhanced, setEnhanced] = useState(cols)

  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={enhanced}
      title='Användare'
      model='users'
      label='User'
      validationSchema={validationSchema}
    />
  )
}
 export default Users

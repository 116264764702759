import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import Table from './components/Table'
import { dataService } from './services'

const cols = [
  {
    id: 1,
    key: 'article_id',
    label: 'Artikel',
    human: 'article',
    type: 'number',
    sortable: true,
    editable: true,
    filterable: true,
    options: [],
    display: true
  },
  {
    id: 4,
    key: 'storage_id',
    label: 'Lagerplats',
    human: 'storage',
    type: 'number',
    sortable: true,
    editable: true,
    filterable: true,
    options: [],
    display: true,
    multichange: true
    // model: []
  },
  {
    id: 5,
    key: 'status_id',
    label: 'Status',
    human: 'status',
    type: 'number',
    sortable: true,
    editable: true,
    filterable: true,
    options: [],
    display: true,
    multichange: true
  },
  {
    id: 6,
    key: 'comment',
    label: 'Kommentar',
    type: 'text',
    editable: true,
    display: true,
    multiline: true,
    hidden: true
  },
  {
    id: 7,
    key: 'amount',
    label: 'Antal',
    type: 'number',
    sortable: true,
    editable: true,
    display: true,
    hidden: false
  }
]

const validationSchema = Yup.object().shape({
  article_id: Yup.number().nullable().required('Ange artikel'),
  manufacturer_number: Yup.string().nullable(),
  storage_id: Yup.number().required('Ange lagerplats'),
  status_id: Yup.number().required('Ange status'),
  amount: Yup.number().required('Ange antal')
})

const Bulk = ({
  match,
  history,
  location
}) => {
  useEffect(() => {
    dataService.getAll({model: 'storages', params: '?pagination=off'})
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({ value: option.id, label: option.label})
        })
        cols.find(col => col.id === 4).options = options
        dataService.getAll({model: 'statuses', params: '?pagination=off'})
          .then(data => {
            const { result } = data
            const options = []
            result.forEach(option => {
              options.push({ value: option.id, label: option.label})
            })
            cols.find(col => col.id === 5).options = options
            dataService.getAll({model: 'articles', params: '?pagination=off'})
              .then(data => {
                const { result } = data
                const options = []
                result.forEach(option => {
                  options.push({ value: option.id, label: `${option.article_number}: ${option.label}`})
                })
                cols.find(col => col.id === 1).options = options
                setEnhanced(cols)
              })
          })
      })
  }, [])

  const [enhanced, setEnhanced] = useState(cols)

  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={enhanced}
      validationSchema={validationSchema}
      alwaysQuery='bulk=1'
      title='Plockvaror'
      model='article_items'
      label='ArticleItem'
    />
  )
}


export default Bulk

import React from 'react'
import * as Yup from 'yup'

import Table from './components/Table'

const cols = [
  {
    id: 1,
    key: 'internal_number',
    label: 'Id',
    type: 'number',
    sortable: true,
    editable: true,
    filterable: true,
    display: true
  },
  {
    id: 2,
    key: 'label',
    label: 'Titel',
    type: 'text',
    sortable: true,
    editable: true,
    filterable: true,
    display: true,
    identifier: true
  }
]

const validationSchema = Yup.object().shape({
  internal_number: Yup.number().required('Ange id'),
  label: Yup.string().required('Ange titel'),
})

const StockLocations = ({
  match,
  history,
  location
}) => (
  <Table
    match={match}
    history={history}
    location={location}
    cols={cols}
    title='Lagerplatser'
    model='storages'
    label='Storage'
    validationSchema={validationSchema}
  />
)

export default StockLocations

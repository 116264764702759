import React, {useState, useEffect} from 'react'
import { Form, Formik, Field, useField } from 'formik'
import queryString from 'query-string'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import classnames from 'clsx'

// Material
import { makeStyles, lighten } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'
import FilterIcon from '@material-ui/icons/FilterList'
import ClearIcon from '@material-ui/icons/Clear'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'
import ExportFileIcon from './components/icons/ExportFileIcon'
import { CircularProgress } from '@material-ui/core'

import Skeleton from '@material-ui/lab/Skeleton'

import { dataService } from './services'
import TextField from './components/fields/TextField'
import Desert from './images/desert'
import Button from './components/Button'

const useChipStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(1)
  }
}))

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: 70,
    overflow: 'scroll'
  },
  wrapper: {
    width: '100%',
    padding: 24
  },
  drawer: {
    width: 500,
    maxWidth: '80%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20
  },
  fabIcon: {
    marginRight: 10
  },
  circle: {
    display: 'inline-flex'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    zIndex:1,
    top:0,
    left: 0,
    right: 0
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  highlight: {
    backgroundColor: lighten(theme.palette.primary.main, 1),
    color: theme.palette.primary.main
  }
}))

const title = 'Logg'
const model = 'logs'
const cols = [
  {
    id: 1,
    key: 'created_at',
    label: 'Datum',
    type: 'date',
    sortable: true,
  },
  {
    id: 2,
    key: 'series_number',
    label: 'Mätarnummer',
    type: 'text',
    filterable: true,
  },
  {
    id: 3,
    key: 'manufacturer_number',
    label: 'Tillverkningsnummer',
    type: 'text',
    filterable: true,
  },
  {
    id: 4,
    key: 'article_number',
    label: 'Artikelnummer',
    type: 'text',
    filterable: true,
  },
  {
    id: 5,
    key: 'message',
    label: 'Meddelande',
    type: 'text',
  },
]

const SelectedFilters = ({
  onDelete,
  filters,
}) => {
  const classes = useChipStyles()
  return (
    <div style={{ paddingBottom: 10 }}>
      {Object.keys(filters).map(filter => {
        const { label, human, options } = cols.find(col => col.key === filter)

        let test = () => <React.Fragment><Typography variant='subtitle2' color=''>{`${label} | `}</Typography><Typography variant='subtitle2'>&nbsp;{`${filters[filter]}`}</Typography></React.Fragment>
        if (human && options.length > 0) {
          test = () => <React.Fragment><Typography variant='subtitle2' color=''>{`${label} | `}</Typography><Typography variant='subtitle2'>&nbsp;{`${options.find(option => option.value === parseInt(filters[filter], 10)).label}`}</Typography></React.Fragment>
        }

        return (
          <Chip color='primary' className={classes.chip} key={filter} label={test()} onDelete={() => onDelete(filter)}/>
        )
      })}
    </div>
  )
}

const Log = ({
  match,
  history,
  location,
  startQuery,
  noAction,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const params = queryString.parse(location.search)
  const usedFilters = {}
  const selectedFilterValues = {}
  Object.keys(params).forEach(q => {
    if (q.includes('[key') && !q.includes('[1000]')) {
      const key = q.split('between[').pop().split('][key]')[0]
      const value = []
      let string = ''
      JSON.parse(params[`between[${key}][value]`]).forEach((date, index) => {
        if (index > 0) {
          string = `${string} - `
        }
        if (date === '0000-00-00' || date === '3000-01-01') {
          value.push(null)
          string = `${string}...`
        } else {
          value.push(date)
          string = `${string}${date}`
        }
      })
      selectedFilterValues[params[q]] = string
      usedFilters[params[q]] = value
    }
  })

  // Lifecycle methods
  useEffect(() => {
    setLoading(true)
    dataService.getAll({ model, params: `?${queryString.stringify(params)}${Object.keys(params).length > 0 ? '&' : ''}&order_by=created_at`})
      .then(data => {
        const { result, total } = data
        setData(result)
        setTotal(total)
        setQuery(params)
        setFilters(usedFilters)
        setLoading(false)
      })
  }, [location.search])

  // State hooks
  const [exporting, setExporting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState({})
  const [query, setQuery] = useState(params)
  const [filters, setFilters] = useState(usedFilters)
  const [readOnly, setReadOnly] = useState(false)
  const [selected, setSelected] = useState([])
  const [option, setOption] = useState({})
  const [toDelete, setToDelete] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)

  // Style
  const classes = useStyles()

  // Methods
   const handleChangePage = (event, newPage) => {
    const sortQuery = {...query, page: newPage + 1}
    reRoute(sortQuery)
  }

  const handleChangeRowsPerPage = event => {
    const sortQuery = {...query, per_page: event.target.value }
    reRoute(sortQuery)
  }

  const handleSort = property => {
    let direction = 'desc'
    if (query['order_by'] === property && query.direction !== 'asc') {
      direction = 'asc'
    }
    const sortQuery = {...query, order_by: property, direction, page: 1}
    reRoute(sortQuery)
  }

  const handleExport = () => {
    setExporting(true)
    let select = ''
    cols.map((col, index) => col.visible && (
      select += `&select[${index}]=${col.key}`
    ))
    const params = `?pagination=off${select}&${queryString.stringify(query, {encode: false})}`
    dataService.getXlsx({ model: `${model}.xlsx`, params})
      .then(
        data => {
          const filename = `${title || model}_${format(new Date(), 'yyyyMMddHHmmss')}.xlsx`
          setExporting(false)
          enqueueSnackbar(filename, { variant: 'success' })
          return saveAs(new Blob([data]), filename)
        },
        error => {
          if (Array.isArray(error)) {
            error.forEach(err => {
              enqueueSnackbar(err.message || err.type, { variant: 'error' })
            })
          } else {
            if (typeof(error) === 'object') {
              error = 'Systemfel, kontakta support'
            }
            setExporting(false)
            enqueueSnackbar(error, { variant: 'error' })
          }
        }
      )
  }

  const handleFilter = values => {
    setFilterOpen(false)
    let search = ''
    if (values.created_at_after || values.created_at_before) {
      search = `between[0][key]=created_at&between[0][value]=["${values.created_at_after || '0000-00-00'}", "${values.created_at_before || '3000-01-01'}"]`
    }
    if (values.model_key) {
      search += (search.length > 0 ? '&' : '?') + `filter[0]key=model_key&filter[0][value]=${values.model_key}`
    }
    return history.push({
      pathname: match.url,
      search
    })
  }

  const handleRemoveFilter = filter => {
    const byKeyValue = Object.keys(query).find(key => query[key] === filter )
    const valueKey = byKeyValue.replace('key', 'value')
    const filterQuery = {...query}
    delete filterQuery[byKeyValue]
    delete filterQuery[valueKey]
    reRoute(filterQuery)
  }

  const handleClearFilter = () => {
    setOpen(false)
    setCurrent({})
    setLoading(true)
    const allFilters = Object.keys(query).filter(key => key.includes('[key]') || key.includes('[value]'))
    const filterQuery = {...query}
    allFilters.forEach(filter => {
      delete filterQuery[filter]
    })
    reRoute(filterQuery)
  }

  const reRoute = q => {
    let search = ''
    for (const key of Object.keys(q)) {
      search += `&${key}=${q[key]}`
    }
    search = search.replace('&', '?')
    return history.push({
      pathname: match.url,
      search
    })
  }

  return (
    <React.Fragment>
      {Object.keys(filters).length > 0 && (
        <SelectedFilters filters={selectedFilterValues} cols={cols} onDelete={filter => handleRemoveFilter(filter)} />
      )}
      <div style={{ width: '100%', paddingBottom: 5 }}>
        {loading ?
          <Skeleton variant='rect' width={150} height={20}/>
         : (total > 0 && (
            <Typography variant='caption' color='textSecondary'>{total} {title} hittades. Sida {query.page || 1} av {Math.ceil(total / (query.per_page ? query.per_page : 30))}</Typography>
          ))
        }
      </div>
      <Paper className={classes.paper}>
        <Toolbar className={classnames(classes.toolbar, {
          [classes.highlight]: false,
        })}>
          <Typography variant='h6'>
            {title}
          </Typography>
          <div>
            {data.length > 0 && (
              <Tooltip title='Exportera'>
                <IconButton onClick={() => handleExport()}>
                  {exporting ? (
                    <CircularProgress size={24}/>
                  ) : (
                    <ExportFileIcon />
                  )}
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title='Filtrera' aria-label='filter'>
              <IconButton onClick={() => setFilterOpen(true)}>
                {Object.keys(filters).length > 0 ? (
                  <Badge badgeContent={Object.keys(filters).length} color='primary'>
                    <FilterIcon />
                  </Badge>
                ) : (
                  <FilterIcon />
                )}
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {cols && cols.map(col => !col.hidden && (
                <TableCell key={col.id}>
                  {col.sortable ? (
                    <TableSortLabel
                      active={query['order_by'] === col.key}
                      direction={query['direction'] || 'desc'}
                      onClick={() => handleSort(col.key)}
                    >
                      {col.label}
                    </TableSortLabel>
                  ): (col.label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? data.map(item => {
              return (
                <TableRow hover key={item.id}>
                  {cols && cols.map(col => !col.hidden && (
                    <TableCell key={col.id} align={col.rightAlign}>
                      {!loading ? (
                          <React.Fragment>
                            {col.type === 'date' ? (
                                moment(item[col.key]).format('YYYY-MM-DD HH:mm')
                              ) : (
                                item[col.key]
                              )
                            }
                          </React.Fragment>
                        ) : (
                          <Skeleton variant='rect' />
                        )
                      }
                    </TableCell>
                  ))}
                </TableRow>
              )
            }) : (
              <React.Fragment>
                {!loading ? (
                    <TableRow>
                      <TableCell colSpan={cols.filter(col => !col.hidden).length + 1} style={{ padding: 50 }}>
                          <Desert
                            style={{
                              marginLeft: 'auto',
                              margin: 'auto',
                              marginBottom: 30,
                              width: 250,
                              maxWidth: '100%',
                              height: 'auto',
                              display: 'flex'
                            }}
                          />
                          <Typography component='h2' variant='h5' align='center'>
                            Inga träffar :(
                          </Typography>
                        </TableCell>
                    </TableRow>
                  ) : (
                  [...Array(30)].map((e, i) => (
                    <TableRow key={i} />
                  ))
                )}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[30, 50, 100]}
          labelRowsPerPage='Rader per sida'
          component="div"
          count={total}
          labelDisplayedRows={({from , to, count}) => `${from}-${to} av ${count}`}
          rowsPerPage={query.per_page ? query.per_page : 30}
          page={query.page ? query.page - 1 : 0 }
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <Drawer
          classes={{
            paper: classes.drawer
          }}
          anchor='right'
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
        >
          <Toolbar className={classes.toolbar}>
            <Typography variant='h6'>
              Filtrera
            </Typography>
            <IconButton onClick={() => setFilterOpen(false)}>
              <ClearIcon />
            </IconButton>
          </Toolbar>
          <div className={classes.wrapper}>
            <Formik
              initialValues={{
                created_at_after: filters['created_at'] && filters['created_at'][0],
                created_at_before: filters['created_at'] && filters['created_at'][1]
              }}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => handleFilter(values)}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                setFieldTouched
              }) => (
                <Form className={classes.form}>
                  <Field
                    variant='outlined'
                    type='date'
                    margin='normal'
                    name='created_at_after'
                    fullWidth
                    component={TextField}
                    label='Efter datum'
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <Field
                    variant='outlined'
                    type='date'
                    margin='normal'
                    name='created_at_before'
                    fullWidth
                    component={TextField}
                    label='Före datum'
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <div className={classes.actions}>
                    <Button
                      size='large'
                      onClick={handleClearFilter}
                      fullWidth
                      variant='outlined'
                      disabled={isSubmitting}
                      color='primary'
                      label='Rensa filter'
                    />
                    <Button
                      type='submit'
                      size='large'
                      fullWidth
                      variant='contained'
                      disabled={isSubmitting}
                      color='primary'
                      label='Filtrera'
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Drawer>
      </Paper>
    </React.Fragment>
  )
}

export default Log

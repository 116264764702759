import React from 'react'
import InputBase from '@material-ui/core/InputBase'

const SearchField = ({
  field: { ...fields},
  form: { touched, errors, ...rest},
  ...props
}) => (
  <InputBase
    {...props}
    {...fields}
    error={Boolean(touched[fields.name] && errors[fields.name])}
  />
)

export default SearchField

import React, {useCallback, useState, useEffect, useRef} from 'react'
import { useReactToPrint } from 'react-to-print'
import classnames from 'clsx'
import * as Yup from 'yup'
import { Prompt } from 'react-router-dom'
import {useDropzone} from 'react-dropzone'
import xlsx from 'xlsx'
import { Form, Formik, FieldArray, Field, getIn } from 'formik'
import { useSnackbar } from 'notistack'

import { makeStyles, lighten } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Fab from '@material-ui/core/Fab'
import SaveIcon from '@material-ui/icons/Save'
import AddIcon from '@material-ui/icons/PlaylistAdd'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import ImportIcon from '@material-ui/icons/GetApp'
import CircularProgress from '@material-ui/core/CircularProgress'
import Drawer from '@material-ui/core/Drawer'

import Skeleton from '@material-ui/lab/Skeleton'

import Excel from './images/excel'
import TextField from './components/fields/TextField'
import CustomSnackbar from './components/CustomSnackbar'
import ReactSelect from './components/fields/ReactSelect'
import Button from './components/Button'
import SplitButton from './components/SplitButton'

import { dataService } from './services'
import BarcodeField from './components/fields/BarcodeField'
import { FormHelperText, FormControl } from '@material-ui/core'
import ArticlePrint from './ArticlePrint'

const useStyles = makeStyles(theme =>({
  paper: {
    width: '100%',
    marginBottom: 70
  },
  drawer: {
    width: 500,
    maxWidth: '80%'
  },
  wrapper: {
    width: '100%',
    padding: 24
  },
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20
  },
  fabIcon: {
    marginRight: 10
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    zIndex:1,
    top:0,
    left: 0,
    right: 0
  },
  highlight: {
    backgroundColor: lighten(theme.palette.primary.main, 1),
    color: theme.palette.primary.main
  }
}))

// const Wrapper = ({getRootProps, children, articleLength,...rest}) => (
//   <React.Fragment>
//     {articleLength > 0 ? (
//       <Paper {...rest}>
//         {children}
//       </Paper>
//     ) : (
//       <Paper {...getRootProps()} {...rest}>
//         {children}
//       </Paper>
//     )}
//   </React.Fragment>
// ) 


const ErrorMessage = ({ name, value }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name)
      const touch = getIn(form.touched, name);
      return touch && error ? <FormControl error><FormHelperText>{error}</FormHelperText></FormControl> : null;
    }}
  />
)

const Import = () => {
  useEffect(() => {
    dataService.getAll({model: 'articles', params: '?pagination=off'})
      .then(data => {
        const { result } = data
        const tmpOptions = []
        const tmpMap = {}
        result.forEach(option => {
          tmpOptions.push({ value: option.id, label: `${option.article_number}: ${option.label}`})
          tmpMap[option.article_number] = option.id
        })
        setOptions(tmpOptions)
        console.log(tmpOptions)
        setMap(tmpMap)
        dataService.getAll({ model: 'storages', params: '?pagination=off' })
          .then(data => {
            const { result } = data
            const tmpStorages = []
            result.forEach(storage => {
              tmpStorages.push({ value: storage.id, label: storage.label })
            })
            setStorages(tmpStorages)
            dataService.getAll({ model: 'statuses', params: '?pagination=off' })
              .then(data => {
                const { result } = data
                const tmpStatuses= []
                result.forEach(status => {
                  tmpStatuses.push({ value: status.id, label: status.label })
                })
                setStatuses(tmpStatuses)
              })
          })
      })

    return () => {
      closeSnackbar()
    }
  }, [])

  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [articles, setArticles] = useState([])
  const [map, setMap] = useState({})
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [storages, setStorages] = useState([])
  const [statuses, setStatuses] = useState([])
  const [selected, setSelected] = useState([])
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [current, setCurrent] = useState({})
  const [imported, setImported] = useState([])

  const inputRef = useRef(null)
  const printRef = useRef()

  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({
    articles: Yup.array()
      .of(
        Yup.object().shape({
          article_id: Yup.number().nullable().required('Ange artikel'),
          series_number: Yup.string().nullable()
            .when('manufacturer_number', {
              is: value => !Boolean(value),
              then: Yup.string().required('Ange mätarnr eller tillverkningsnr')
            }),
        })
      )
  }))
  const onDropAccepted = useCallback(acceptedFiles => {
    setLoading(true)
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onabort = () => setLoading(false)
    reader.onerror = () => setLoading(false)
    reader.onload = e => {
      const bstr = e.target.result
      const wb = xlsx.read(bstr, {type:rABS ? 'binary' : 'array'})
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      const data = xlsx.utils.sheet_to_json(ws, {header:1})
      setLoading(false)
      const objectArray = []
      const validations = {}
      data.forEach((row, index) => {
        objectArray.push({ article_id: map[row[0]], series_number: row[1], manufacturer_number: row[2] })
      })
      setArticles(objectArray)
    }
    acceptedFiles.forEach(file => rABS ? reader.readAsBinaryString(file) : reader.readAsArrayBuffer(file))
  }, [map])
  const onDropRejected = useCallback(() => {
    enqueueSnackbar('Filformatet stöds inte', {
      variant: 'error',
      style: {
        bottom: 90
      },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      }
    })
  })

  const handleMultiChange = option => {
    setDrawerOpen(!drawerOpen)
    setCurrent(option)
  }

  const isSelected = index => selected.indexOf(index) !== -1

  const handleSelectClick = (event, index) => {
    if (selected.indexOf(index) !== -1) {
      setSelected([...selected.slice(0, selected.indexOf(index)), ...selected.slice(selected.indexOf(index) + 1)])
    } else {
      setSelected([...selected, index])
    }
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = articles.map((d, index) => index)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  })

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({onDropAccepted, onDropRejected, noClick: true, accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
  return (
    <React.Fragment>
      <Paper className={classes.paper} {...getRootProps()} style={{opacity: isDragActive ? 0.4 : 1}}>
      {/*<Wrapper className={classes.paper} articleLength={articles.length} getRootProps={getRootProps} style={{opacity: isDragActive ? 0.4 : 1}}>*/}
          <input {...getInputProps()} />
          <Toolbar className={classnames(classes.toolbar, {
            [classes.highlight]: selected.length > 0
          })}>
            {selected.length > 0 ? (
              <React.Fragment>
                <Typography variant='subtitle1'>
                  {selected.length} markerade
                </Typography>
                <SplitButton
                  options={[{key: 'article_id', label: 'Artikel', options}, {key: 'storage_id', label: 'Lagerplats', options: storages }, {key: 'status_id', label: 'Status', options: statuses}]}
                  handleClick={option => handleMultiChange(option)}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography variant='h6'>
                  Inleverans
                </Typography>
                <IconButton onClick={open}>
                  <ImportIcon />
                </IconButton>
              </React.Fragment>
            )}
          </Toolbar>
          <Formik
            validationSchema={validationSchema}
            initialValues={{articles}}
            enableReinitialize
            onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
              const { articles } = values
              dataService.create({ values: articles, model: 'article_items' })
                .then(
                  data => {
                    setSubmitting(false)
                    setArticles([])
                    setImported(data)
                    resetForm()
                    enqueueSnackbar('', {
                      persist: true,
                      style: {
                        bottom: 90
                      },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                      },
                      children: (key) => (
                          <CustomSnackbar className={classes.test} id={key} header='Inleverans färdig' onPrint={handlePrint} />
                      )
                    })
                  },
                  error => {
                    setSubmitting(false)
                    if (Array.isArray(error)) {
                      error.forEach(e => enqueueSnackbar(e, {variant: 'error'}))
                    } else {
                      enqueueSnackbar(error,{variant: 'error'})
                    }
                    setStatus(error)
                  }
                )
            }}
          >
            {({
              values,
              errors, 
              touched,
              status,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched
            }) => (
              <Form>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      {values.articles.length > 0 && (
                        <TableCell padding='checkbox'>
                          <Checkbox
                            indeterminate={selected.length > 0 && selected.length < values.articles.length}
                            color='primary'
                            checked={selected.length === values.articles.length}
                            onChange={handleSelectAllClick}
                            inputProps={{ 'aria-label': 'select all'}}
                          />
                        </TableCell>
                      )}
                      <TableCell>{values.articles.length > 0 && 'Artikel'}</TableCell>
                      <TableCell>{values.articles.length > 0 && 'Mätarnummer'}</TableCell>
                      <TableCell>{values.articles.length > 0 && 'Tillverkningsnummer'}</TableCell>
                      <TableCell>{values.articles.length > 0 && 'Lagerplats'}</TableCell>
                      <TableCell>{values.articles.length > 0 && 'Status'}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray
                      name='articles'
                      render={arrayHelpers => (
                        <React.Fragment>
                          {values.articles.length > 0 && values.articles.map((article, index) => {
                            const isItemSelected = isSelected(index)
                            const labelId = `table-checkbox-${index}`
                            return (
                              <TableRow key={index}>
                                <TableCell padding='checkbox'>
                                  {loading ? (
                                    <Skeleton variant='react' />
                                  ) : (
                                    <Checkbox
                                      checked={isItemSelected}
                                      onChange={event => handleSelectClick(event, index)}
                                      inputProps={{ 'aria-labelledby': labelId }}
                                      color='primary'
                                    />
                                  )}
                                </TableCell>
                                <TableCell style={{minWidth: 200}}>
                                  {loading ? (
                                      <Skeleton variant='rect' />
                                    ) : (
                                      <React.Fragment>
                                        <ReactSelect
                                          name={`articles[${index}][article_id]`}
                                          dense
                                          options={options}
                                          placeholder={`Välj artikel`}
                                          label={'Artikel'}
                                          onChange={option => option.value ? setFieldValue(`articles[${index}][article_id]`, option.value) : undefined}
                                          onBlur={() => setFieldTouched(`articles[${index}][article_id]`, true)}
                                          value={options.find(option => option.value === values['articles'][index]['article_id'])}
                                          errors={errors}
                                          touched={touched}
                                        />
                                        <ErrorMessage name={`articles[${index}].article_id`} />
                                      </React.Fragment>
                                    )
                                  }
                                </TableCell>
                                <TableCell>
                                  {loading ? (
                                      <Skeleton variant='rect' />
                                    ) : (
                                      <React.Fragment>
                                        <Field
                                          variant='outlined'
                                          margin='dense'
                                          fullWidth
                                          InputProps={{
                                            inputProps: {
                                              ref: inputRef,
                                            }
                                          }}
                                          onComplete={() => {
                                            inputRef.current.focus()
                                          }}
                                          type='text'
                                          name={`articles[${index}][series_number]`}
                                          component={BarcodeField}
                                        />
                                        <ErrorMessage name={`articles[${index}].series_number`} />
                                      </React.Fragment>
                                    )
                                  }
                                </TableCell>
                                <TableCell>
                                  {loading ? (
                                      <Skeleton variant='rect' />
                                    ) : (
                                      <Field
                                        variant='outlined'
                                        margin='dense'
                                        fullWidth
                                        InputProps={{
                                          inputProps: {
                                            ref: inputRef
                                          }
                                        }}
                                        type='text'
                                        name={`articles[${index}][manufacturer_number]`}
                                        component={TextField}
                                      />
                                    )
                                  }
                                </TableCell>
                                <TableCell style={{minWidth: 200}}>
                                  {loading ? (
                                      <Skeleton variant='rect' />
                                    ) : (
                                      <ReactSelect
                                        name={`articles[${index}][storage_id]`}
                                        dense
                                        options={storages}
                                        placeholder={`Välj lagerplats`}
                                        label={'Lagerplats'}
                                        onChange={option => setFieldValue(`articles[${index}][storage_id]`, option.value)}
                                        onBlur={() => setFieldTouched(`articles[${index}][storage_id]`, true)}
                                        value={storages.find(storage => storage.value === values['articles'][index]['storage_id'])}
                                        errors={errors}
                                        touched={touched}
                                      />
                                    )
                                  }
                                </TableCell>
                                <TableCell style={{minWidth: 200}}>
                                  {loading ? (
                                      <Skeleton variant='rect' />
                                    ) : (
                                      <ReactSelect
                                        name={`articles[${index}][status_id]`}
                                        dense
                                        options={statuses}
                                        placeholder={`Välj status`}
                                        label={'Status'}
                                        onChange={option => setFieldValue(`articles[${index}][status_id]`, option.value)}
                                        onBlur={() => setFieldTouched(`articles[${index}][status_id]`, true)}
                                        value={statuses.find(status => status.value === values['articles'][index]['status_id'])}
                                        errors={errors}
                                        touched={touched}
                                      />
                                    )
                                  }
                                </TableCell>
                                <TableCell align='right'>
                                  {loading ? (
                                      <Skeleton variant='circle' width={43} height={43} style={{ display: 'inline-flex' }}/>
                                    ) : (
                                      <IconButton
                                        aria-label='delete'
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    )
                                  }
                                </TableCell>
                              </TableRow>
                            )}
                          )}
                          {
                            values.articles <= 0 && (
                              <React.Fragment>
                                {loading ? (
                                  [...Array(10)].map((e, i) => (
                                    <TableRow key={i}>
                                      <TableCell padding='checkbox'>
                                        <Skeleton variant='rect' />
                                      </TableCell>
                                      <TableCell>
                                        <Skeleton variant='rect' />
                                      </TableCell>
                                      <TableCell>
                                        <Skeleton variant='rect' />
                                      </TableCell>
                                      <TableCell>
                                        <Skeleton variant='rect' />
                                      </TableCell>
                                      <TableCell>
                                        <Skeleton variant='rect' />
                                      </TableCell>
                                      <TableCell>
                                        <Skeleton variant='rect' />
                                      </TableCell>
                                      <TableCell align='right'>
                                        <Skeleton variant='circle' width={43} height={43} style={{ display: 'inline-flex' }}/>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={7} style={{ padding: 50 }}>
                                      <Excel
                                        color={'#4daf50'}
                                        style={{
                                          marginLeft: 'auto',
                                          margin: 'auto',
                                          marginBottom: 30,
                                          display: 'flex'
                                        }}
                                      />
                                      <Typography component='h2' variant='h5' align='center'>
                                        Ladda upp fil (*.xlsx)
                                      </Typography>
                                      <Typography component='h3' variant='subtitle1' align='center' style={{marginTop: 10}}>
                                        Drag och släpp fil från dator eller
                                        <Button
                                          onClick={open} 
                                          style={{ margin: '0 0 0 10px'}} 
                                          variant='contained'
                                          size='small'
                                          color='primary'
                                          label='Klicka här'
                                        />
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  )}
                              </React.Fragment>
                            )
                          }
                          <TableRow>
                            <TableCell padding='checkbox'/>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell align='right'>
                              <IconButton
                                  aria-label='add'
                                  onClick={() => {
                                    const newArticle = {article_id: null, series_number: '', manufacturer_number: ''}
                                    setArticles([...values.articles, newArticle])
                                    return arrayHelpers.push(newArticle)
                                  }}
                                  color='primary'
                                >
                                  <AddIcon />
                                </IconButton>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )}
                    />
                  </TableBody>
                </Table>
                <Prompt
                  when={articles.length > 0}
                  message='Dina ändringar är inte sparade. Är du säker på att du vill gå vidare?'
                />
                <Fab
                  variant='extended'
                  aria-label='submit'
                  type='submit'
                  color='primary'
                  disabled={isSubmitting || values.articles.length < 1}
                  className={classes.fab}
                >
                  <SaveIcon className={classes.fabIcon} />
                  {isSubmitting && <CircularProgress style={{position: 'absolute', left: 5, color: '#fff'}} size={45} />}
                  Spara
                </Fab>
              </Form>
            )}
          </Formik>
          <Drawer
            classes={{
              paper: classes.drawer
            }}
            anchor='right'
            open={drawerOpen}
            onClose={() => handleMultiChange({})}
          >
            <Toolbar className={classes.toolbar}>
              <Typography variant='h6'>
                Ändra
              </Typography>
              <IconButton onClick={() => handleMultiChange({})}>
                <ClearIcon />
              </IconButton>
            </Toolbar>
            <div className={classes.wrapper}>
              <Formik
                onSubmit={(values, {setSubmitting, setStatus }) => {
                  const newArticles = articles.map((item, index) => {
                    if (selected.includes(index)) {
                      const returnValue = {...item, [current.key]: values[current.key]}
                      return returnValue
                    }
                    return item
                  })
                  // const bulk = articles.filter((item, index) => selected.includes(index))
                  // const bulkData = bulk.map((item, index) => {
                  //   const returnValue = {...item, [current.key]: values[current.key]}
                  //   return returnValue
                  // })
                  // const newArticles = [...bulkData, ...articles.filter((item, index) => !selected.includes(index))]
                  setArticles(newArticles)
                  setSubmitting(false)
                  handleMultiChange({})
                  // setSelected([])
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  status,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched
                }) => (
                  <Form>
                      <ReactSelect
                        name={current.key}
                        options={current.options}
                        placeholder={`Välj ${current.label}`}
                        label={current.label}
                        onChange={sel => setFieldValue(current.key, sel.value)}
                        onBlur={() => setFieldTouched(current.key, true)}
                        errors={errors}
                        touched={touched}
                      />
                    <Button
                      type='submit'
                      size='large'
                      fullWidth
                      variant='contained'
                      disabled={isSubmitting}
                      color='primary'
                      label='Spara'
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </Drawer>
      {/*</Wrapper>*/}
      </Paper>
      <div style={{ display: 'none' }}>
        <ArticlePrint ref={printRef} data={imported} title={'Inleverans'} />
      </div>
    </React.Fragment>
  )
}

export default Import

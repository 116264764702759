import { handleResponse, checkPermission } from '../helpers'
import { get, post, put, softDelete, getExport } from '../utils'

const map = {
  users: 'User',
  storages: 'Storage',
  articles: 'Article',
  article_items: 'ArticleItem',
  activities: 'Activity',
  permissions: 'Permission',
  roles: 'Role',
  statuses: 'Status',
  logs: 'Log',
}

const beforeGet = model => new Promise((resolve, reject) => {
  if (checkPermission({label: map[model], type:'read'})) {
    return resolve()
  } else {
    return reject()
  }
})

const getAll = ({ model, params, skip }) => !skip ? beforeGet(model).then(() => get(`/${model}${params}`)).then(handleResponse) : get(`/${model}${params}`).then(handleResponse)

const getXlsx = ({ model, params }) => getExport(`/${model}${params}`)
  .then(handleResponse)

const create = ({ model, values }) => post(`/${model}`, values)
  .then(handleResponse)
  .then(data => data)

const edit = ({ model, values }) => put(`/${model}`, values).then(handleResponse).then(data => data)

const remove = ({ model }) => softDelete(`/${model}`).then(handleResponse).then(data => data)

const find = ({ model, values }) => post(`/${model}/find`, values)
  .then(handleResponse)
  .then(data => data)

const dataService = {
    getAll,
    getXlsx,
    create,
    edit,
    remove,
    find
}

export default dataService
import React, {useEffect, useState} from 'react'
import TextField from '@material-ui/core/TextField'

export default ({
  field: { ...fields }, 
  form: { touched, errors, ...rest },
  onComplete,
  ...props
}) => {
  const [refresh, setRefresh] = useState()
  const [data, setData] = useState({
    options: {
      onComplete, // Callback after detection of a successful scanning
      onError: error => {console.log(error)}, // Callback after detection of a unsuccessful scanning
      onReceive: data => {console.log(data)}, // Callback after receive a char
      timeBeforeScanTest: 100, // Wait duration (ms) after keypress event to check if scanning is finished
      avgTimeByChar: 30, // Average time (ms) between 2 chars. Used to do difference between keyboard typing and scanning
      minLength: 4, // Minimum length for a scanning
      endChar: [9, 13], // Chars to remove and means end of scanning
      stopPropagation: false, // Stop immediate propagation on keypress event
      preventDefault: false // Prevent default action on keypress event
    },
    firstCharTime: 0,
    lastCharTime: 0,
    stringWriting: '',
    callIsScanner: false,
    testTimer: false
  })

  useEffect(() => {
    setData({
      ...data,
      options: {
        ...data.options,
        onComplete
      }
    })
  }, [onComplete])

  useEffect(() => {
    setData({
      ...data,
      firstCharTime: 0,
      lastCharTime: 0,
      stringWriting: '',
      callIsScanner: false,
      testTimer: false
    })
  }, [refresh])

  const keypress = e => {
    if (data.options.stopPropagation) e.stopImmediatePropagation()
    if (data.options.preventDefault) e.preventDefault()
    if (data.firstCharTime && data.options.endChar.indexOf(e.which) !== -1) {
      e.preventDefault()
      e.stopPropagation()
      data.callIsScanner = true
    } else {
      data.stringWriting += String.fromCharCode(e.which)
      data.callIsScanner = false
    }

    if (!data.firstCharTime) {
      data.firstCharTime = e.timeStamp
    }
    data.lastCharTime = e.timeStamp

    if (data.testTimer) clearTimeout(data.testTimer)
    if (data.callIsScanner) {
      scannerDetectionTest()
      data.testTimer = false
    } else {
      data.testTimer = setTimeout(() => {
        scannerDetectionTest()
      }, data.options.timeBeforeScanTest)
    }

    if (data.options.onReceive) data.options.onReceive.call(data, e)
    trigger('scannerDetectionReceive', { evt: e })
  }

  const trigger = options => {}

  const scannerDetectionTest = s => {
     // If string is given, test it
     if (s) {
      data.firstCharTime = data.lastCharTime = 0
      data.stringWriting = s
    }
    // If all condition are good (length, time...), call the callback and re-initialize the plugin for next scanning
    // Else, just re-initialize
    if (
      data.stringWriting.length >= data.options.minLength &&
      data.lastCharTime - data.firstCharTime <
        data.stringWriting.length * data.options.avgTimeByChar
    ) {
      if (data.options.onComplete) {
        data.options.onComplete.call(data, data.stringWriting)
      }
      initScannerDetection()
      return true
    } else {
      if (data.options.onError) {
        data.options.onError.call(data, data.stringWriting)
      }
      initScannerDetection()
      return false
    }
  }
  const initScannerDetection = () => {
    setRefresh(Date.now())
  }

  return (
  <TextField
    {...props}
    {...fields}
    onKeyDown={e => keypress(e)}
    error={Boolean(touched[fields.name] && errors[fields.name])}
    helperText={touched[fields.name] && errors[fields.name]} 
  />
)}

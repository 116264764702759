import React from 'react'

export default ({style, color, size = 26}) => (
  <svg
    version="1.1"
    id="Lager_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 59 59"
    style={{enableBackground: 'new 0 0 59 59'}}
    xmlSpace="preserve"
    width={size}
    height={size}
  >
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="18.2641" y1="30.0737" x2="40.8853" y2="30.0737">
  <stop  offset="0" style={{stopColor:'#00052A'}}/>
  <stop  offset="0.988" style={{stopColor:'#2581C4'}}/>
</linearGradient>
<polygon style={{fill:'url(#SVGID_1_)'}} points="39.4,3 11.2,3 5.2,31.6 54,57.1 "/>
<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="13.5098" y1="30.0737" x2="31.8457" y2="30.0737">
  <stop  offset="0" style={{ stopColor: '#2581C4'}}/>
  <stop  offset="1" style={{stopColor:'#2581C4', stopOpacity:0 }}/>
</linearGradient>
<polygon style={{fill:'url(#SVGID_2_)'}} points="16.5,16.7 16.5,8.3 23.6,3 11.2,3 0,57.1 20.6,49.4 20.6,13.6 "/>
</svg>
)


// export default ({style, color}) => (
//  <svg
//   version="1.1"
//   id="Lager_1"
//   xmlns="http://www.w3.org/2000/svg"
//   xmlnsXlink="http://www.w3.org/1999/xlink"
//   x="0px"
//   y="0px"
//   viewBox="0 0 59 59"
//   style={{enableBackground: 'new 0 0 59 59'}}
//   xmlSpace="preserve"
//   width="20"
//   height="20"
// >
// <polygon style={{fill: 'none', stroke: '#1B71AA', strokeWidth:4, strokeMiterlimit: 10 }} points="37.7,0.5 29.5,0.5 33.1,49.9 53.3,58.1" />
// <polygon style={{fill: 'none', stroke: '#1B71AA', strokeWidth:4, strokeMiterlimit: 10 }} points="18.3,15.1 18.3,6.1 25.8,0.5 12.6,0.5 0.7,58.1 22.7,49.9 21.2,12.8"/>
// </svg>
// )

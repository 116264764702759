import React, {useState, useEffect} from 'react'
import { Form, Formik, Field, useField } from 'formik'
import queryString from 'query-string'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import classnames from 'clsx'

// Material
import { makeStyles, lighten } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ViewIcon from '@material-ui/icons/RemoveRedEye'
import Drawer from '@material-ui/core/Drawer'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import FilterIcon from '@material-ui/icons/FilterList'
import ClearIcon from '@material-ui/icons/Clear'
import HistoryIcon from '@material-ui/icons/History'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'
import ExportFileIcon from './icons/ExportFileIcon'
import { CircularProgress } from '@material-ui/core'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'

import Skeleton from '@material-ui/lab/Skeleton'

import { checkPermission } from '../helpers'
import { dataService } from '../services'
import TextField from './fields/TextField'
import ReactSelect from './fields/ReactSelect'
import CheckboxField from './fields/CheckboxField'
import Desert from '../images/desert'
import Button from '../components/Button'
import SplitButton from '../components/SplitButton'


const useViewStyles = makeStyles(theme => ({
  avatar: {
    color: '#4daf50',
    marginLeft: 'auto'
  }
}))

const useFilterStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    padding: 24,
    height: '100%'
  },
  form: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  actions: {
    marginTop: 'auto'
  },
  submit: {
    marginTop: 15
  }

}))

const useChipStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(1)
  }
}))

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: 70,
    overflow: 'scroll'
  },
  wrapper: {
    width: '100%',
    padding: 24
  },
  drawer: {
    width: 500,
    maxWidth: '80%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20
  },
  fabIcon: {
    marginRight: 10
  },
  circle: {
    display: 'inline-flex'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    zIndex:1,
    top:0,
    left: 0,
    right: 0
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  highlight: {
    backgroundColor: lighten(theme.palette.primary.main, 1),
    color: theme.palette.primary.main
  }
}))

const Activities = ({ model, id }) => {
  useEffect(() => {
    dataService.getAll({ model: `${model}/${id}/activities`, params: '?order_by=created_at', skip: true})
      .then(data => {
          const { result } = data
          setActivities(result)
        }
      )
  }, [])

  const classes = useViewStyles()

  const [activities, setActivities] = useState([])

  return (
    <React.Fragment>
      {activities.length > 0 && (
        <Paper style={{ marginTop: 10 }}>
          <Toolbar>
            <Typography variant='subtitle1'>
              Historik
            </Typography>
            <HistoryIcon className={classes.avatar}/>
          </Toolbar>
          <Divider />
          <List dense={true}>
            {activities.map(activity => (
              <ListItem key={activity.id}>
                <ListItemText
                  primary={activity.human}
                  secondary={moment(activity.created_at).format('YYYY-MM-DD HH:mm')}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </React.Fragment>
  )
}


const SelectedFilters = ({
  onDelete,
  filters,
  cols
}) => {
  const classes = useChipStyles()
  return (
    <div style={{ paddingBottom: 10 }}>
     
      {Object.keys(filters).map(filter => {
        const { label, human, options } = cols.find(col => col.key === filter)
        
        let test = () => <React.Fragment><Typography variant='subtitle2' color=''>{`${label} | `}</Typography><Typography variant='subtitle2'>&nbsp;{`${filters[filter]}`}</Typography></React.Fragment>
        if (human && options.length > 0) {
          test = () => <React.Fragment><Typography variant='subtitle2' color=''>{`${label} | `}</Typography><Typography variant='subtitle2'>&nbsp;{`${options.find(option => option.value === parseInt(filters[filter], 10)).label}`}</Typography></React.Fragment>
        }

        return (
          <Chip color='primary' className={classes.chip} key={filter} label={test()} onDelete={() => onDelete(filter)}/>
        )
      })}
      { /* Object.keys(filters).map(filter => (
        <Chip key={filter} label={filters[filter]} onDelete={() => onDelete(filter)}/>
      )) */}
    </div>
  )
}

const Filter = ({
  cols,
  model,
  filters,
  onFilter,
  onClearFilter
}) => {
  const classes = useFilterStyles()
  return (
    <div className={classes.wrapper}>
      <Formik
        initialValues={filters}
        enableReinitialize
        onSubmit={(values, {setSubmitting, setStatus}) => onFilter(values).then(response => {setSubmitting(false)})}
      >
        {({
          values,
          errors,
          touched,
          status,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched
        }) => (
          <Form className={classes.form}>
            {cols && cols.map(col => col.filterable && (
              <React.Fragment key={col.id}>
                {col.options ? (
                  <ReactSelect
                    name={col.key}
                    options={col.options}
                    placeholder={`Välj ${col.label}`}
                    label={col.label}
                    onChange={option => setFieldValue(col.key, option.value)}
                    onBlur={() => setFieldTouched(col.key, true)}
                    value={col.options.find(option => option.value === values[col.key])}
                    errors={errors}
                    touched={touched}
                  />
                ) : (
                  <Field
                    key={col.id}
                    variant='outlined'
                    margin='normal'
                    type={col.type || 'text'}
                    name={col.key}
                    fullWidth
                    component={TextField}
                    label={col.label}
                  />
                )}
              </React.Fragment>
            ))}
            <div className={classes.actions}>
              <Button
                size='large'
                onClick={onClearFilter}
                fullWidth
                variant='outlined'
                disabled={isSubmitting}
                color='primary'
                label='Rensa filter'
              />
              <Button
                type='submit'
                size='large'
                fullWidth
                variant='contained'
                disabled={isSubmitting}
                color='primary'
                label='Filtrera'
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const Edit =({
  item,
  cols,
  model,
  readOnly,
  onCreated,
  onUpdated,
  hideActivities,
  validationSchema
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  return (
    <div className={classes.wrapper}>
      {
        readOnly ? (
          <React.Fragment>
            <List>
            {cols.map(col => col.display && (
              <ListItem key={col.id}>
                {col.boolean ? (
                    <ListItemText
                      primary={<Typography variant='subtitle2' color='textSecondary'>{col.label}</Typography>}
                      secondary={<Checkbox checked={item[col.key]} disabled />}
                    />
                  ) : (
                  <ListItemText
                    primary={<Typography variant='subtitle2' color='textSecondary'>{col.label}</Typography>}
                    secondary={<Typography variant='subtitle1'>{col.human ? item[col.human] : item[col.key]}</Typography>}
                  />
                )}
              </ListItem>
            ))}
            </List>
            {!hideActivities && (
              <Activities model={model} id={item.id} />
            )}
          </React.Fragment>
      ) : (
        <React.Fragment>
          {item === 'create' ? (
              <Formik
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting, setStatus }) => {
                  dataService.create({ values, model})
                    .then(
                      data => {
                        setSubmitting(false)
                        onCreated()
                      },
                      error => {
                        setSubmitting(false)
                        enqueueSnackbar(error, {variant: 'error'})
                        // setStatus(error)
                      })
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  status,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched
                }) => (
                  <Form>
                    {cols && cols.map(col => (col.editable || col.create) && (
                      <React.Fragment key={col.id}>
                        {col.options ? (
                            <ReactSelect
                              name={col.key}
                              options={col.options}
                              placeholder={`Välj ${col.label}`}
                              label={col.label}
                              onChange={option => setFieldValue(col.key, option.value)}
                              onBlur={() => setFieldTouched(col.key, true)}
                              errors={errors}
                              touched={touched}
                            />
                          ) : (
                            <Field
                              variant='outlined'
                              margin='normal'
                              multiline={col.multiline}
                              type={col.type || 'text'}
                              name={col.key}
                              fullWidth
                              component={TextField}
                              label={col.label}
                            />
                          )
                        }
                      </React.Fragment>
                    ))}
                    <Button
                      type='submit'
                      size='large'
                      fullWidth
                      variant='contained'
                      disabled={isSubmitting}
                      color='primary'
                      label='Spara'
                    />
                  </Form>
                )}
              </Formik>
            ) : (
              <Formik
                initialValues={item}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting, setStatus }) => {
                  const persistantKeys = Object.keys(values).filter(key => !cols.filter(col => col.editable).some(t => t.key === key))
                  persistantKeys.forEach(key => {
                    delete values[key]
                  })
                  dataService.edit({ values, model: `${model}/${item.id}`})
                    .then(
                      data => {
                        setSubmitting(false)
                        onUpdated()
                      },
                      error => {
                        setSubmitting(false)
                        enqueueSnackbar(error, {variant: 'error'})
                        // setStatus(error)
                      })
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  status,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched
                }) => (
                  <Form>
                      {cols && cols.map(col => col.editable && (
                         <React.Fragment key={col.id}>
                        {col.options ? (
                          <ReactSelect
                            name={col.key}
                            onChange={option => setFieldValue(col.key, option.value)}
                            onBlur={() => setFieldTouched(col.key, true)}
                            errors={errors}
                            touched={touched}
                            value={col.options.find(option => option.value === values[col.key])}
                            options={col.options}
                            placeholder={`Välj ${col.label}`}
                            label={col.label}
                          />
                        ) : (
                          <React.Fragment>
                            {col.boolean ? (
                                <Field
                                  name={col.key}
                                  type='checkbox'
                                  value={values[col.key]}
                                  checked={values[col.key]}
                                  handleToggle={event => {setFieldValue(col.key, event.target.checked)} }
                                  label={col.label}
                                  component={CheckboxField}
                                  color='primary'
                                />
                              ) : (
                                <Field
                                  variant='outlined'
                                  margin='normal'
                                  type={col.type || 'text'}
                                  multiline={col.multiline}
                                  name={col.key}
                                  fullWidth
                                  component={TextField}
                                  label={col.label}
                                />
                              )
                            }
                          </React.Fragment>
                        )}
                      </React.Fragment>
                      ))}
                      <Button
                        type='submit'
                        size='large'
                        fullWidth
                        variant='contained'
                        disabled={isSubmitting}
                        color='primary'
                        label='Spara'
                      />
                  </Form>
                )}
              </Formik>
            )
          }
        </React.Fragment>
      )}
    </div>
  )
}

const Multi = ({
  onUpdated,
  data,
  selected,
  model,
  option
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  return (
    <div className={classes.wrapper}>
      <Formik
        onSubmit={(values, {setSubmitting, setStatus }) => {
          const bulk = data.filter(item => selected.includes(item.id))
          const bulkData = { values: bulk.map(item => {
            const returnValue = {id: item.id, [option.key]: values[option.key]}
            // returnValue[option.key] = values[option.key]
            return returnValue
          })}
          
          dataService.edit({ values: bulkData.values, model})
            .then(
              data => {
                setSubmitting(false)
                onUpdated()
              },
              error => {
                setSubmitting(false)
                enqueueSnackbar(error, {variant: 'error'})
                
              }
            )
        }}
      >
        {({
          values,
          errors,
          touched,
          status,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched
        }) => (
          <Form>
            {option.options ? (
              <ReactSelect
                name={option.key}
                options={option.options}
                placeholder={`Välj ${option.label}`}
                label={option.label}
                onChange={selected => setFieldValue(option.key, selected.value)}
                onBlur={() => setFieldTouched(option.key, true)}
                errors={errors}
                touched={touched}
              />
            ) : (
               <Field
                  variant='outlined'
                  margin='normal'
                  multiline={option.multiline}
                  type={option.type || 'text'}
                  name={option.key}
                  fullWidth
                  component={TextField}
                  label={option.label}
                />
            )}
            <Button
              type='submit'
              size='large'
              fullWidth
              variant='contained'
              disabled={isSubmitting}
              color='primary'
              label='Spara'
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const GenericTable = ({
  match,
  history,
  location,
  title,
  cols,
  model,
  label,
  locked,
  hideActivities,
  validationSchema,
  startQuery,
  alwaysQuery,
  noAction,
  exportable
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const params = queryString.parse(location.search)
  const usedFilters = {}
  Object.keys(params).forEach(q => {
    if (q.includes('[key]')) {
      const key = q.split('filter[').pop().split('][key]')[0]
      usedFilters[params[q]] = params[`filter[${key}][value]`]
    }
  })

  // Lifecycle methods
  useEffect(() => {
    setLoading(true)
    const hasParams = Object.keys(params).length > 0
    dataService.getAll({ model, params: `?${queryString.stringify(params)}${hasParams ? '&' : ''}${startQuery || ''}${((hasParams || startQuery) ? '&' : '')}${alwaysQuery || ''}`})
      .then(data => {
        const { result, total } = data
        setData(result)
        setTotal(total)
        setQuery(params)
        setFilters(usedFilters)
        setLoading(false)

      })
  }, [location.search])

  // State hooks
  const [exporting, setExporting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState({})
  const [query, setQuery] = useState(params)
  const [filters, setFilters] = useState(usedFilters)
  const [readOnly, setReadOnly] = useState(false)
  const [selected, setSelected] = useState([])
  const [option, setOption] = useState({})
  const [toDelete, setToDelete] = useState(false)

  // Style
  const classes = useStyles()

  // Methods
  const handleDrawer = (item, readOnly = false) => {
    setOpen(!open)
    setCurrent(item)
    setReadOnly(readOnly)
  }

  const handleMultiChange = option => {
    setOpen(!open)
    setCurrent('multi')
    setOption(option)
  }

   const handleChangePage = (event, newPage) => {
    const sortQuery = {...query, page: newPage + 1}
    reRoute(sortQuery)
  }

  const handleChangeRowsPerPage = event => {
    const sortQuery = {...query, per_page: event.target.value }
    reRoute(sortQuery)
  }

  const handleSort = property => {
    let direction = 'desc'
    if (query['order_by'] === property && query.direction !== 'asc') {
      direction = 'asc'
    }
    const sortQuery = {...query, order_by: property, direction, page: 1}
    reRoute(sortQuery)
  }

  const handleExport = () => {
    setExporting(true)
    let select = ''
    cols.map((col, index) => col.visible && (
      select += `&select[${index}]=${col.key}`
    ))
    const params = `?pagination=off${select}&${queryString.stringify(query, {encode: false})}`
    dataService.getXlsx({ model: `${model}.xlsx`, params})
      .then(
        data => {
          const filename = `${title || model}_${format(new Date(), 'yyyyMMddHHmmss')}.xlsx`
          setExporting(false)
          enqueueSnackbar(filename, { variant: 'success' })
          return saveAs(new Blob([data]), filename)
        },
        error => {
          if (Array.isArray(error)) {
            error.forEach(err => {
              enqueueSnackbar(err.message || err.type, { variant: 'error' })
            })
          } else {
            if (typeof(error) === 'object') {
              error = 'Systemfel, kontakta support'
            }
            setExporting(false)
            enqueueSnackbar(error, { variant: 'error' })
          }
        }
      )
  }

  const showUpdated = () => {
    const search = '?order_by=updated_at'
    setOpen(false)
    setCurrent({})
    setLoading(true)
    history.push({
      pathname: match.url,
      search
    })
    return dataService.getAll({ model, params: `?${queryString.stringify(params)}` + (alwaysQuery ? `&${alwaysQuery}` : '')})
      .then(data => {
        const { result, total } = data
        setData(result)
        setTotal(total)
        setQuery(params)
        setFilters(filters)
        setLoading(false)
      })
  }

  const showCreated = () => {
    const search = '?order_by=created_at'
    setOpen(false)
    setCurrent({})
    setLoading(true)
    history.push({
      pathname: match.url,
      search
    })
    return dataService.getAll({ model, params: `?${queryString.stringify(params)}` + (alwaysQuery ? `&${alwaysQuery}` : '')})
    .then(data => {
        const { result, total } = data
        setData(result)
        setTotal(total)
        setQuery(params)
        setFilters(filters)
        setLoading(false)
    })
  }

  const handleFilter = async values => {
    setOpen(false)
    setCurrent({})
    setLoading(true)
    const filterQuery = {...query }
    Object.keys(values).forEach((key, index) => {
      filterQuery[`filter[${index}][key]`] = key
      filterQuery[`filter[${index}][value]`] = values[key]
    })
    reRoute(filterQuery)
  }

  const handleRemoveFilter = filter => {
    const byKeyValue = Object.keys(query).find(key => query[key] === filter )
    const valueKey = byKeyValue.replace('key', 'value')
    const filterQuery = {...query}
    delete filterQuery[byKeyValue]
    delete filterQuery[valueKey]
    reRoute(filterQuery)
  }

  const handleClearFilter = () => {
    setOpen(false)
    setCurrent({})
    setLoading(true)
    const allFilters = Object.keys(query).filter(key => key.includes('[key]') || key.includes('[value]'))
    const filterQuery = {...query}
    allFilters.forEach(filter => {
      delete filterQuery[filter]
    })
    reRoute(filterQuery)
  }

  const handleConfirm = () => {
    dataService.remove({ model: `${model}/${toDelete.id}`, params: ''}).then(
      data => {
        enqueueSnackbar(data.message, {variant: 'success'})
        history.push({
          pathname: match.url,
          search: `?removed=${toDelete.id}`
        })
        setToDelete(false)
        return
      },
        error => {
        setToDelete(false)
        enqueueSnackbar(error, {variant: 'error'})
        return
      }
    )
  }

  const handleDelete = item => {
    setToDelete(item)
    //  dataService.remove({ model: `${model}/${item.id}`, params: ''}).then(
    //   data => {
    //     enqueueSnackbar(data.message, {variant: 'success'})
    //     return history.push({
    //       pathname: match.url,
    //       search: `?removed=${item.id}`
    //     })
    //   },
    //     error => {
    //     enqueueSnackbar(error, {variant: 'error'})
    //     return
    //   }
    // )
  }

  const isSelected = id => selected.indexOf(id) !== -1

  const handleSelectClick = (event, id) => {
    if (selected.indexOf(id) !== -1) {
      setSelected([...selected.slice(0, selected.indexOf(id)), ...selected.slice(selected.indexOf(id) + 1)])
    } else {
      setSelected([...selected, id])
    }
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = data.map(d => d.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const reRoute = q => {
    let search = ''
    for (const key of Object.keys(q)) {
      search += `&${key}=${q[key]}`
    }
    search = search.replace('&', '?')
    return history.push({
      pathname: match.url,
      search
    })
  }

  return (
    <React.Fragment>
      {Object.keys(filters).length > 0 && (
        <SelectedFilters filters={filters} cols={cols} onDelete={filter => handleRemoveFilter(filter)}/>
      )}
      <div style={{ width: '100%', paddingBottom: 5 }}>
        {loading ?
          <Skeleton variant='rect' width={150} height={20}/>
         : (total > 0 && (
            <Typography variant='caption' color='textSecondary'>{total} {title} hittades. Sida {query.page || 1} av {Math.ceil(total / (query.per_page ? query.per_page : 30))}</Typography>
          ))
        }
      </div>
      <Paper className={classes.paper}>
        <Toolbar className={classnames(classes.toolbar, {
          [classes.highlight]: selected.length > 0 && cols.some(col => col.multichange) && checkPermission({label, type: 'write'})
        })}>
          {selected.length > 0 && cols.some(col => col.multichange && checkPermission({label, type: 'write'})) ? (
            <Typography variant='subtitle1'>
              {selected.length} markerade
            </Typography>
          ) : (
            <Typography variant='h6'>
              {title}
            </Typography>
          )}
          {selected.length > 0 && cols.some(col => col.multichange) && checkPermission({label, type: 'write'}) ? (
            <SplitButton
              options={cols.filter(col => col.multichange)}
              handleClick={(option) => handleMultiChange(option)}
            />
          ) : (
            <div>
              {exportable && (
                <Tooltip title='Exportera'>
                  <IconButton onClick={() => handleExport()}>
                    {exporting ? (
                      <CircularProgress size={24}/>
                    ) : (
                      <ExportFileIcon />
                    )}
                  </IconButton>
                </Tooltip>
              )}
              {cols && cols.some(col => col.filterable) && (
                <Tooltip title='Filtrera' aria-label='filter'>
                  <IconButton onClick={() => handleDrawer('filter')}>
                    {Object.keys(filters).length > 0 ? (
                      <Badge badgeContent={Object.keys(filters).length} color='primary'>
                        <FilterIcon />
                      </Badge>
                    ) : (
                      <FilterIcon />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
        </Toolbar>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {cols.some(col => col.multichange) && checkPermission({label, type: 'write'}) && (
                <TableCell padding='checkbox'>
                   <Checkbox
                      indeterminate={selected.length > 0 && selected.length < data.length}
                      color='primary'
                      checked={!loading && selected.length === data.length}
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                </TableCell>
              )}
              {cols && cols.map(col => !col.hidden && (
                <TableCell key={col.id}>
                  {col.sortable ? (
                    <TableSortLabel
                      active={query['order_by'] === col.key}
                      direction={query['direction'] || 'desc'}
                      onClick={() => handleSort(col.key)}
                    >
                      {col.label}
                    </TableSortLabel>
                  ): (col.label)}
                </TableCell>
              ))}
              {!noAction && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? data.map(item => {
              const isItemSelected = isSelected(item.id)
              const labelId = `table-checkbox-${item.id}`
              return (
                <TableRow hover key={item.id}>
                  {cols.some(col => col.multichange) && checkPermission({label, type: 'write'}) && (
                    <TableCell padding='checkbox'>
                      {!loading ? (
                        <Checkbox
                          checked={isItemSelected}
                          onChange={event => handleSelectClick(event, item.id)}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color='primary'
                        />
                      ) : (
                        <Skeleton variant='rect' />
                      )}
                    </TableCell>
                  )}
                  {cols && cols.map(col => !col.hidden && (
                    <TableCell key={col.id} align={col.rightAlign}>
                      {!loading ? (
                          <React.Fragment>
                            {
                              col.human ? (
                                item[col.human]
                              ) : (
                                <React.Fragment>
                                  {col.boolean ? (
                                      <Checkbox
                                        checked={item[col.key]}
                                        disabled
                                      />
                                    ) : (
                                      <React.Fragment>
                                        {col.type === 'date' ? (
                                            moment(item[col.key]).format('YYYY-MM-DD HH:mm')
                                          ) : (
                                            item[col.key]
                                          )
                                        }
                                      </React.Fragment>
                                    )
                                  }
                                </React.Fragment>
                              )
                            }
                          </React.Fragment>
                        ) : (
                          <Skeleton variant='rect' />
                        )
                      }
                    </TableCell>
                  ))}
                  {!noAction && (
                    <TableCell style={{ whiteSpace: 'nowrap' }} align='right'>
                      {!loading ? (
                        <React.Fragment>
                          {checkPermission({label, type: 'read'}) && (
                            <Tooltip title='Visa' aria-label='view'>
                              <IconButton onClick={() => handleDrawer(item, true)}>
                                <ViewIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {checkPermission({label, type: 'write'}) && (
                            <Tooltip title='Ändra' aria-label='edit'>
                              <IconButton onClick={() => handleDrawer(item)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {checkPermission({label, type: 'remove'}) && !locked && (
                          <Tooltip title='Ta bort' aeia-label='delete'>
                            <IconButton onClick={() => handleDelete(item)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {checkPermission({label, type: 'read'}) && <Skeleton variant='circle' width={43} height={43} classes={{ circle: classes.circle }} style={{marginRight:10}}/>}
                          {checkPermission({label, type: 'write'}) && <Skeleton variant='circle' width={43} height={43} classes={{ circle: classes.circle }} style={{marginRight:10}}/>}
                          {checkPermission({label, type: 'remove'}) && !locked && <Skeleton variant='circle' width={43} height={43} classes={{ circle: classes.circle }} />}
                        </React.Fragment>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              )
            }) : (
              <React.Fragment>
                {!loading ? (
                    <TableRow>
                      <TableCell colSpan={cols.filter(col => !col.hidden).length + 1} style={{ padding: 50 }}>
                          <Desert
                            style={{
                              marginLeft: 'auto',
                              margin: 'auto',
                              marginBottom: 30,
                              width: 250,
                              maxWidth: '100%',
                              height: 'auto',
                              display: 'flex'
                            }}
                          />
                          <Typography component='h2' variant='h5' align='center'>
                            Inga träffar :(
                          </Typography>
                        </TableCell>
                    </TableRow>
                  ) : (
                  [...Array(30)].map((e, i) => (
                    <TableRow key={i}>
                      {cols && cols.some(col => col.multichange) && checkPermission({label, type: 'write'}) && (
                        <TableCell padding='checkbox'>
                          <Skeleton variant='rect' />
                        </TableCell>
                      )}
                      {cols && cols.map(col => !col.hidden && (
                        <TableCell key={col.id}>
                          <Skeleton variant='rect' />
                        </TableCell>
                      ))}
                      {!noAction && (
                       <TableCell align='right'>
                        {checkPermission({label, type: 'read'}) && <Skeleton variant='circle' width={43} height={43} classes={{ circle: classes.circle }} style={{marginRight:10}}/>}
                        {checkPermission({label, type: 'write'}) && <Skeleton variant='circle' width={43} height={43} classes={{ circle: classes.circle }} style={{marginRight:10}}/>}
                        {checkPermission({label, type: 'remove'}) && <Skeleton variant='circle' width={43} height={43} classes={{ circle: classes.circle }} />}
                       </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[30, 50, 100]}
          labelRowsPerPage='Rader per sida'
          component="div"
          count={total}
          labelDisplayedRows={({from , to, count}) => `${from}-${to} av ${count}`}
          rowsPerPage={query.per_page ? query.per_page : 30}
          page={query.page ? query.page - 1 : 0 }
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <Drawer
          classes={{
            paper: classes.drawer
          }}
          anchor='right'
          open={open}
          onClose={() => handleDrawer({})}
        >
          <Toolbar className={classes.toolbar}>
            <Typography variant='h6'>
              {current === 'create' ? 'Skapa ny' : (current === 'filter' ? 'Filtrera' : (readOnly ? 'Visa' : 'Ändra'))}
            </Typography>
            <IconButton onClick={() => handleDrawer({})}>
              <ClearIcon />
            </IconButton>
          </Toolbar>
          {current === 'filter' ? (
            <Filter
              cols={cols}
              model={model}
              filters={filters}
              onFilter={values => handleFilter(values)}
              onClearFilter={() => handleClearFilter()}
            />
            ) : (
            current === 'multi' ? (
              <Multi
                data={data}
                selected={selected}
                onUpdated={() => showUpdated()}
                cols={cols}
                model={model}
                option={option}
              />
            ) : (
              <Edit
                item={current}
                onCreated={() => showCreated()}
                onUpdated={() => showUpdated()}
                cols={cols}
                model={model}
                readOnly={readOnly}
                hideActivities={hideActivities}
                validationSchema={validationSchema}
              />
            )
          )}
        </Drawer>
        <Dialog
          open={Boolean(toDelete)}
          onClose={() => setToDelete(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Ta bort {cols.find(col => col.identifier) && toDelete[cols.find(col => col.identifier).key]}?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
             Är du säker på att du vill ta bort {cols.find(col => col.identifier) && toDelete[cols.find(col => col.identifier).key]}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setToDelete(false)} 
              color='primary'
              label='Avbryt'
            />
            <Button
              onClick={handleConfirm}
              color='primary'
              variant='contained'
              autoFocus
              label='Ta bort'
            />
          </DialogActions>
        </Dialog>
        {checkPermission({label, type: 'write'}) && !locked && !noAction && (
          <Fab
            variant='extended'
            aria-label='submit'
            type='submit'
            color='primary'
            onClick={() => handleDrawer('create')}
            className={classes.fab}
          >
            <AddIcon className={classes.fabIcon} />
            Skapa ny
          </Fab>
          )
        }
      </Paper>
    </React.Fragment>
  )
}

export default GenericTable

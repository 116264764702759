import React, { Component } from 'react'
import moment from 'moment'

// Material
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

const cols = [
  {
    id: 1,
    key: 'updated_at',
    label: 'Datum',
    type: 'date',
  },
  {
    id: 2,
    key: 'article',
    label: 'Artikel',
    human: 'article',
    type: 'text',
  },
  {
    id: 3,
    key: 'series_number',
    label: 'Mätarnummer',
    type: 'text',
  },
  {
    id: 4,
    key: 'manufacturer_number',
    label: 'Tillverkningsnummer',
    type: 'text',
  },
  {
    id: 5,
    key: 'storage',
    label: 'Lagerplats',
    human: 'storage',
    type: 'text',
  },
  {
    id: 6,
    key: 'status',
    label: 'Status',
    human: 'status',
    type: 'text',
  }
]

const styles = {
  container: {
    padding: '32px 16px',
  },
  title: {
    paddingLeft: 16,
    marginBottom: 32,
  }
}

class ArticlePrint extends Component {
  render() {
    const { title, data } = this.props

    return (
      <div style={styles.container}>
          <Typography style={styles.title}>{title} {moment().format('YYYY-MM-DD HH:mm')}</Typography>
          <Table size='small'>
            <TableHead>
              <TableRow>
                {cols && cols.map(col => !col.hidden && (
                  <TableCell key={col.id}>
                    {col.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(item => {
                return (
                  <TableRow hover key={item.id}>
                    {cols && cols.map(col => !col.hidden && (
                      <TableCell key={col.id} align={col.rightAlign}>
                        <React.Fragment>
                          {col.type === 'date' ? (
                              moment(item[col.key]).format('YYYY-MM-DD HH:mm')
                            ) : (
                              item[col.key]
                            )
                          }
                        </React.Fragment>
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
    )
  }
}

export default ArticlePrint

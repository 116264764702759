import React from 'react'
import classnames from 'clsx'
import AsyncSelect from 'react-select/async'
import { emphasize, makeStyles, withStyles, withTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import CancelIcon from '@material-ui/icons/Cancel'

import { dataService } from '../../services'

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
    minWidth: 290,
  },
  textField: {
    marginTop: 16,
    marginBottom: 8
  },
  denseTextField: {
    marginTop: 8,
    marginBottom: 4
  },
  input: {
    display: 'flex',
    padding: '10px 14px',
    height: 'auto',
  },
  denseInput: {
    height: '1.28em'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    // left: 2,
    // bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 3,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  }
})

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 250,
    minWidth: 290,
  },
  textField: {
    marginTop: 16,
    marginBottom: 8
  },
  denseTextField: {
    marginTop: 8,
    marginBottom: 4
  },
  input: {
    display: 'flex',
    padding: '10px 14px',
    height: 'auto',
  },
  denseInput: {
    height: '1.28em'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    // left: 2,
    // bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 3,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}))

const NoOptionsMessage = props => (
  <Typography
    color='textSecondary'
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
)

const inputComponent = ({inputRef, ...props}) => (
  <div ref={inputRef} {...props} />
)

const Control = ({
  children,
  innerProps,
  innerRef,
  selectProps: { classes, TextFieldProps, form: { touched, errors, name, dense } },
  isDisabled,
  ...rest
}) => {
  // debugger
  return (<TextField
    fullWidth
    disabled={isDisabled}
    className={classnames(classes.textField, { [classes.denseTextField]: dense })}
    variant='outlined'
    error={Boolean(touched[name] && errors[name])}
    helperText={touched[name] && errors[name]}
    InputProps={{
      inputComponent,
      inputProps: {
        className: classnames(classes.input, { [classes.denseInput]: dense }),
        ref: innerRef,
        children,
        ...innerProps
      }
    }}
    {...TextFieldProps}
  />)
}

const Option = props => (
  <MenuItem
    ref={props.innerRef}
    selected={props.isFocused}
    component='div'
    style={{
      fontWeight: props.isSelected ? 500 : 400
    }}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>
)

const Placeholder = ({
  selectProps,
  innerProps = {},
  children
}) => {
  return (
  <Typography color='textSecondary' className={selectProps.classes.placeholder} {...innerProps}>
    {children}
  </Typography>
)}

const SingleValue = props => (
  <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
    {props.children}
  </Typography>
)

const ValueContainer = props => (
  <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
)

const MultiValue = props => (
  <Chip
    tabIndex={-1}
    label={props.children}
    className={classnames(props.selectProps.classes.chip, {
      [props.selectProps.classes.chipFocused]: props.isFocused
    })}
    onDelete={props.removeProps.onClick}
    deleteIcon={<CancelIcon {...props.removeProps } />}
  />
)

const Menu = props => (
  <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
    {props.children}
  </Paper>
)

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}

class ReactSelect extends React.Component {
  state = {
    inputValue: '',
    options: []
  }

  filterOptions = (inputValue, options) => {
    if (inputValue) {
      const filters = options.filter(i => 
        i.label.toLowerCase().indexOf(inputValue.toLowerCase()) === 0
      )

      if (filters.length) {
        return filters
      }
    }
    return []
  }

  loadOptions = (inputValue, callback) => {
    return dataService.getAll({model: 'article_items', params: `?filter[0][key]=series_number&filter[0][value]=${inputValue}`})
      .then(response => {
        const options = []
        if (response && response.result) {
          response.result.forEach(option => {
            options.push({ value: option.id, label: option.series_number})
          })
        }
        return callback(this.filterOptions(inputValue, options))
      })
  }

  render() {
    const { options, placeholder, label, classes, theme, ...props } = this.props

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      })
    }

    return (
      <AsyncSelect
        filterOptions
        classes={classes}
        styles={selectStyles}
        inputId='react-select'
        TextFieldProps={{
          label: label,
          InputLabelProps: {
            htmlFor: 'react-select',
            shrink: true
          }
        }}
        form={{ touched: props.touched, errors: props.errors, name: props.name, dense: props.dense }}
        placeholder={placeholder}
        {...props}
        components={components}
        cacheOptions
        loadOptions={this.loadOptions}
        onInputChange={this.handleInputChange}
      />
    )
  }
}

// const ReactSelect = ({options, ...props}) => (
//   <Select options={options} {...props} />
// )

export default withStyles(styles)(withTheme(ReactSelect))

import React, {useState, useEffect} from 'react'

import Table from './components/Table'
import { dataService } from './services'

const cols = [
  {
    id: 1,
    key: 'human',
    label: 'Händelse',
    type: 'text'
  },
  {
    id: 2,
    key: 'user_id',
    label: 'Användare',
    human: 'user',
    type: 'number',
    sortable: 'true',
    editable: false,
    options: [],
    filterable: true
  },
  {
    id: 3,
    key: 'created_at',
    label: 'Datum',
    type: 'date',
    sortable: true
  }
]

const History = ({
  match, 
  history,
  location
}) => {
  const [enhanced, setEnhanced] = useState(cols)

  useEffect(() => {
    dataService.getAll({ model: 'users', params: '?pagination=off'})
      .then(data => {
        const { result } = data
        const options = []
        result.forEach(option => {
          options.push({value: option.id, label: option.name})
        })
        cols.find(col => col.id === 2).options = options
      })
  })

  return (
    <Table
      match={match}
      history={history}
      location={location}
      cols={enhanced}
      title='Historik'
      model='activities'
      label='Activity'
      startQuery='order_by=created_at'
      noAction
    />
  )
}
 export default History
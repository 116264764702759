import { authenticationService } from '../services'

const handleResponse = response => {
  const { data, status } = response
  if ((data && data.error) || (data && data.errors)) {
    if ([401, 403].includes(status)) {
      authenticationService.logout()
    }
    const error = data.error || data.errors || response.statusText 
    return Promise.reject(error)
  }
  return data
}

export default handleResponse
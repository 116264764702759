import React from 'react';
import { render } from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import Login from './Login';
import Admin from './Admin';
import PrivateRoute from './components/PrivateRoute';
import * as serviceWorker from './serviceWorker';
import Forgot from './Forgot';
import Reset from './Reset';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1a76d0',
    },
    secondary: {
      main: '#1a2430',
      contrastText: '#eeeeee',
    },
    error: {
      main: '#e53934',
    },
    background: {
      default: '#f9f9fc',
    },
  },
});

const useStyles = makeStyles({
  error: {
    backgroundColor: '#e53934',
  },
});

const Test = ({ children }) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      classes={{
        variantError: classes.error,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

theme.shadows[1] = '0 0 14px 0 rgba(53,64,82,.07)';

render(
  <ThemeProvider theme={theme}>
    <Test>
      <CssBaseline />
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/auth/forgot" component={Forgot} />
          <Route exact path="/auth/reset" component={Reset} />
          <PrivateRoute path="/admin" component={Admin} />
        </Switch>
      </Router>
    </Test>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { authenticationService } from './services';

import TextField from './components/fields/TextField';
import Button from './components/Button';
import Logo from './images/logo';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

export default ({ history, location, match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper}>
        <Logo size={70} />
        <Typography
          component="h1"
          variant="h4"
          style={{ marginTop: 10 }}
        >
          OneAsset
        </Typography>
        <Formik
          initialValues={{}}
          enableReinitialize
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Felaktig e-postadress')
              .required('Ange e-postadress'),
          })}
          onSubmit={(
            values,
            { setSubmitting, resetForm, setFieldValue }
          ) => {
            return authenticationService.forgot(values.email).then(
              (data) => {
                setSubmitting(false);
                resetForm();
                setFieldValue('email', '');
                enqueueSnackbar(
                  'Ett mail har skickats med instruktioner',
                  { variant: 'success' }
                );
              },
              (error) => {
                setSubmitting(false);
                if (Array.isArray(error)) {
                  error.forEach((err) => {
                    enqueueSnackbar(err.message || err.type, {
                      variant: 'error',
                    });
                  });
                } else {
                  enqueueSnackbar(error, { variant: 'error' });
                }
              }
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            status,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
          }) => (
            <Form className={classes.form}>
              <Field
                variant="outlined"
                margin="normal"
                type="email"
                name="email"
                fullWidth
                autoFocus
                component={TextField}
                label="E-postadress"
              />
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                disabled={isSubmitting}
                color="primary"
                label="Återställ lösenord"
              >
                {isSubmitting && (
                  <CircularProgress
                    style={{ position: 'absolute' }}
                    size={24}
                  />
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

import React from 'react'
import * as Yup from 'yup'

import Table from './components/Table'

const cols = [
  {
    id: 1,
    key: 'article_number',
    label: 'Artikelnummer',
    type: 'text',
    sortable: true,
    editable: true,
    filterable: true,
    display: true
  },
  {
    id: 2,
    key: 'label',
    label: 'Titel',
    type: 'text',
    sortable: true,
    editable: true,
    filterable: true,
    display: true,
    identifier: true
  }
]

const validationSchema = Yup.object().shape({
  article_number: Yup.string().required('Ange artikelnummer'),
  label: Yup.string().required('Ange titel'),
})

const Articles = ({
  match,
  history,
  location
}) => (
  <Table
    match={match}
    history={history}
    location={location}
    cols={cols}
    title='Artikelnummer'
    model='articles'
    label='Article'
    validationSchema={validationSchema}
  />
)

export default Articles

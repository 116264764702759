import React from 'react'
import MuiTextfield from '@material-ui/core/TextField'

const TextField = ({
  field: { ...fields},
  form: { touched, errors, ...rest},
  ...props
}) => (
  <MuiTextfield
    {...props}
    {...fields}
    error={Boolean(touched[fields.name] && errors[fields.name])}
    helperText={touched[fields.name] && errors[fields.name]}
  />
)

export default TextField


import React, { useState } from 'react'
import { Route, Link } from 'react-router-dom'
import classNames from 'clsx'
import { Form, Formik, Field } from 'formik'

import { fade, makeStyles }from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import SearchIcon from '@material-ui/icons/Search'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import MoveIcon from '@material-ui/icons/AssignmentReturn'
import ImportIcon from '@material-ui/icons/AssignmentReturned'
import ArticlesIcon from '@material-ui/icons/Assignment'
import UsersIcon from '@material-ui/icons/Group'
import Typography from '@material-ui/core/Typography'
import LocationIcon from '@material-ui/icons/Room'
import CategoryIcon from '@material-ui/icons/Category'
import StatusIcon from '@material-ui/icons/Speed'
import PermissionsIcon from '@material-ui/icons/Lock'
import HistoryIcon from '@material-ui/icons/History'
import ChartIcon from '@material-ui/icons/ShowChart'
import LogoutIcon from '@material-ui/icons/PowerSettingsNew'
import LogIcon from '@material-ui/icons/Warning'
import BulkIcon from './images/bulk'

import Import from './Import'
import Move from './Move'
import Dashboard from './Dashboard'
import Users from './Users'
import Items from './Items'
import Bulk from './Bulk'
import Articles from './Articles'
import StockLocations from './StockLocations'
import Statuses from './Statuses'
import Permissions from './Permissions'
import Log from './Log'
import History from './History'
import StockBalance from './StockBalance'
import SearchField from './components/fields/SearchField'
import { authenticationService } from './services'
import Notifications from './components/Notifications'
import Logo from './images/logo'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 24
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  list: {
    color: theme.palette.secondary.contrastText
  },
  icon: {
    color: 'inherit'
  },
  fixedHeight: {
    height: 240,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  selected: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.1),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)'
    }
  },
  breadcrumb: {
    width: '100%'
  }
}))

const checkPermission = ({label, type}) => Boolean(authenticationService.currentUserValue && authenticationService.currentUserValue.permissions && authenticationService.currentUserValue.permissions.length > 0 && authenticationService.currentUserValue.permissions.find(permission => permission.label === label) && authenticationService.currentUserValue.permissions.find(permission => permission.label === label)[type])

const Admin = ({ match, location, history }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const logout = () => {
    authenticationService.logout()
    history.push('/')
  }

  return (
    <div className={classes.root}>
      <AppBar position='absolute' elevation={0} className={classNames(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open-drawer'
            onClick={handleDrawerOpen}
            className={classNames(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Formik
            onSubmit={(values, {setSubmitting, setStatus }) => {
              const { q } = values
              history.push({
                pathname: `${match.url}/items`,
                search: `?q=${q}`
              })
            }}
          >
            {({
              values,
              errors, 
              touched,
              status,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Form className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <Field
                  placeholder="Sök artiklar…"
                  name='q'
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  component={SearchField}
                />
              </Form>
            )}
          </Formik>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            { /* <Notifications /> */}
            <Tooltip title='Logga ut' aria-label='logout'>
              <IconButton aria-label="logout" color="inherit" onClick={logout}>
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        classes={{
          paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 12px'}}>
            <Logo />
            <Typography variant='h6' style={{marginLeft: 12}}>OneAsset</Typography>
          </div>
          <IconButton classes={{ root: classes.icon }} onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List classes={{
          root: classes.list
        }}>
          <Link to={match.url} className={classes.link}>
            <ListItem button className={classNames(classes.button, match.url === location.pathname && classes.selected)}>
              <ListItemIcon classes={{ root: classes.icon }}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary='Skrivbord' />
            </ListItem>
          </Link>

          {checkPermission({label: 'ArticleItem', type: 'write'}) &&
            <Link to={`${match.url}/import`} className={classes.link}>
              <ListItem button className={classNames(classes.button, `${match.url}/import` === location.pathname && classes.selected)}>
                <ListItemIcon classes={{ root: classes.icon }}>
                  <ImportIcon />
                </ListItemIcon>
                <ListItemText primary='Inleverans' />
              </ListItem>
            </Link>
          }
          {checkPermission({label: 'ArticleItem', type: 'write'}) &&
            <Link to={`${match.url}/move`} className={classes.link}>
              <ListItem button className={classNames(classes.button, `${match.url}/move` === location.pathname && classes.selected)}>
                <ListItemIcon classes={{ root: classes.icon }}>
                  <MoveIcon />
                </ListItemIcon>
                <ListItemText primary='Lagerflytt' />
              </ListItem>
            </Link>
          }
          {checkPermission({label: 'ArticleItem', type: 'read'}) &&
             <Link to={`${match.url}/items`} className={classes.link}>
              <ListItem button className={classNames(classes.button, `${match.url}/items` === location.pathname && classes.selected)}>
                <ListItemIcon classes={{ root: classes.icon }}>
                  <ArticlesIcon />
                </ListItemIcon>
                <ListItemText primary='Artiklar' />
              </ListItem>
            </Link>
          }
          {checkPermission({label: 'ArticleItem', type: 'read'}) &&
             <Link to={`${match.url}/bulk`} className={classes.link}>
              <ListItem button className={classNames(classes.button, `${match.url}/bulk` === location.pathname && classes.selected)}>
                <ListItemIcon classes={{ root: classes.icon }}>
                  <BulkIcon />
                </ListItemIcon>
                <ListItemText primary='Plockvaror' />
              </ListItem>
            </Link>
          }
        </List>
        <Divider />
        <List classes={{
          root: classes.list
        }}>
          {checkPermission({label: 'User', type: 'write'}) &&
            <Link to={`${match.url}/users`} className={classes.link}>
              <ListItem button className={classNames(classes.button, `${match.url}/users` === location.pathname && classes.selected)}>
                <ListItemIcon classes={{ root: classes.icon }}>
                  <UsersIcon />
                </ListItemIcon>
                <ListItemText primary='Användare' />
              </ListItem>
            </Link>
          }
          {checkPermission({label: 'Article', type: 'write'}) &&
            <Link to={`${match.url}/articles`} className={classes.link}>
              <ListItem button className={classNames(classes.button, `${match.url}/articles` === location.pathname && classes.selected)}>
                <ListItemIcon classes={{ root: classes.icon }}>
                  <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary='Artikelnummer' />
              </ListItem>
            </Link>
          }
          {checkPermission({label: 'Storage', type: 'write'}) &&
            <Link to={`${match.url}/stock-locations`} className={classes.link}>
              <ListItem button className={classNames(classes.button, `${match.url}/stock-locations` === location.pathname && classes.selected)}>
                <ListItemIcon classes={{ root: classes.icon }}>
                  <LocationIcon />
                </ListItemIcon>
                <ListItemText primary='Lagerplatser' />
              </ListItem>
            </Link>
          }
          {checkPermission({label: 'Status', type: 'write'}) &&
            <Link to={`${match.url}/status`} className={classes.link}>
              <ListItem button className={classNames(classes.button, `${match.url}/status` === location.pathname && classes.selected)}>
                <ListItemIcon classes={{ root: classes.icon }}>
                  <StatusIcon />
                </ListItemIcon>
                <ListItemText primary='Statusar' />
              </ListItem>
            </Link>
          }
          {checkPermission({label: 'Permission', type: 'write'}) &&
            <Link to={`${match.url}/permissions`} className={classes.link}>
              <ListItem button className={classNames(classes.button, `${match.url}/permissions` === location.pathname && classes.selected)}>
                <ListItemIcon classes={{ root: classes.icon }}>
                  <PermissionsIcon />
                </ListItemIcon>
                <ListItemText primary='Behörigheter' />
              </ListItem>
            </Link>
          }
        </List>
        <Divider style={{}}/>
        <List classes={{
          root: classes.list
        }}>
          {checkPermission({label: 'Log', type: 'read'}) &&
            <Link to={`${match.url}/log`} className={classes.link}>
              <ListItem button className={classNames(classes.button, `${match.url}/log` === location.pathname && classes.selected)}>
                <ListItemIcon classes={{ root: classes.icon }}>
                  <LogIcon />
                </ListItemIcon>
                <ListItemText primary='Logg' />
              </ListItem>
            </Link>
          }
          {checkPermission({label: 'Activity', type: 'read'}) &&
            <Link to={`${match.url}/history`} className={classes.link}>
              <ListItem button className={classNames(classes.button, `${match.url}/history` === location.pathname && classes.selected)}>
                <ListItemIcon classes={{ root: classes.icon }}>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary='Historik' />
              </ListItem>
            </Link>
          }
          {checkPermission({label: 'Storage', type: 'read'}) && checkPermission({label: 'ArticleItem', type: 'read'}) &&
            <Link to={`${match.url}/balance`} className={classes.link}>
              <ListItem button className={classNames(classes.button, `${match.url}/balance` === location.pathname && classes.selected)}>
                <ListItemIcon classes={{ root: classes.icon }}>
                  <ChartIcon />
                </ListItemIcon>
                <ListItemText primary='Lagersaldo' />
              </ListItem>
            </Link>
          }
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth='xl' className={classes.container}>
          <Grid container spacing={3}>
            { /* <Typography variant='body2' color='textSecondary' className={classes.breadcrumb}>
                {(() => {
                  switch(location.pathname) {
                    case `${match.url}`: 
                      return 'Skrivbord'
                    case `${match.url}/import`:
                      return 'Inleverans'
                    default:
                      return ''
                  }
                })()}
              </Typography> */}
            <Route exact path={`${match.url}`} component={Dashboard} />
            {checkPermission({label: 'ArticleItem', type: 'write'}) && <Route path={`${match.url}/import`} component={Import} />}
            {checkPermission({label: 'ArticleItem', type: 'write'}) && <Route path={`${match.url}/move`} component={Move} />}
            {checkPermission({label: 'ArticleItem', type: 'read'}) && <Route path={`${match.url}/items`} component={Items} />}
            {checkPermission({label: 'ArticleItem', type: 'read'}) && <Route path={`${match.url}/bulk`} component={Bulk} />}
            {checkPermission({label: 'Article', type: 'write'}) && <Route path={`${match.url}/articles`} component={Articles} />}
            {checkPermission({label: 'User', type: 'write'}) && <Route path={`${match.url}/users`} component={Users} />}
            {checkPermission({label: 'Storage', type: 'write'}) && <Route path={`${match.url}/stock-locations`} component={StockLocations} />}
            {checkPermission({label: 'Status', type: 'write'}) && <Route path={`${match.url}/status`} component={Statuses} />}
            {checkPermission({label: 'Permission', type: 'write'}) && <Route path={`${match.url}/permissions`} component={Permissions} />}
            {checkPermission({label: 'Log', type: 'read'}) && <Route path={`${match.url}/log`} component={Log} />}
            {checkPermission({label: 'Activity', type: 'read'}) && <Route path={`${match.url}/history`} component={History} />}
            {checkPermission({label: 'Storage', type: 'read'}) && checkPermission({label: 'ArticleItem', type: 'read'}) && <Route path={`${match.url}/balance`} component={StockBalance} />}
          </Grid>
        </Container>
      </main>
    </div>
  )
}

export default Admin
import React from 'react'

// export default ({style, color}) => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     version="1.1"
//     id="Layer_1"
//     x="0px"
//     y="0px"
//     viewBox="0 0 454.479 454.479"
//     style={style}
//     xmlSpace="preserve"
//     width="512"
//     height="512">
//   <g id="XMLID_15_">
//     <rect x="7.5" y="370.65" style={{fill:'#E6E6E6'}} width="439.478" height="25.499"/>
//     <circle style={{fill:'#FFD039'}} cx="164.982" cy="108.578" r="50.25"/>
//     <path style={{fill:'#0FBC30'}} d="M89.502,281.978v83h-36v-53v-60c0-9.94,8.06-18,18-18s18,8.06,18,18V281.978z"/>
//     <path style={{fill:'#07A521'}} d="M71.502,251.978c0-6.658,3.625-12.458,9-15.572c-2.65-1.535-5.718-2.428-9-2.428   c-9.94,0-18,8.06-18,18v60v53h18v-53V251.978z"/>
//     <polygon style={{fill:'#999999'}} points="350.502,331.648 369.502,367.648 317.502,367.648 296.662,328.658  "/>
//     <polygon style={{fill:'#CCCCCC'}} points="296.662,328.658 317.502,367.648 136.502,367.648 136.502,366.088 149.132,339.648    189.542,331.868 202.592,306.588 283.832,304.648  "/>
//     <g>
//       <path style={{fill:'#333333'}} d="M7.5,375.149h439.479c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-44.974v-13.674    c9.933-0.045,18-8.135,18-18.078v-0.418c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v0.418c0,1.671-1.339,3.029-3,3.074v-13.492    c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v42.17h-12.978l-16.889-32c-1.229-2.329-3.588-3.842-6.217-3.988l-49.631-2.757    l-10.845-20.29c-1.307-2.444-3.852-3.964-6.613-3.964c-0.06,0-0.12,0-0.181,0.002l-81.239,1.944    c-2.74,0.066-5.228,1.622-6.484,4.058l-11.379,22.042l-36.833,7.089c-2.338,0.45-4.324,1.984-5.35,4.133l-11.333,23.732H97.005    v-68h3.67c11.21,0,20.33-9.12,20.33-20.33v-16.17c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v16.17    c0,2.939-2.391,5.33-5.33,5.33h-3.67v-22.5c0-14.061-11.439-25.5-25.5-25.5s-25.5,11.439-25.5,25.5v52.5h-3.67    c-2.939,0-5.33-2.391-5.33-5.33v-16.17c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v16.17c0,11.21,9.12,20.33,20.33,20.33h3.67    v38H7.5c-4.143,0-7.5,3.358-7.5,7.5S3.357,375.149,7.5,375.149z M345.853,338.901l11.214,21.247H322l-12.435-23.263    L345.853,338.901z M154.269,346.297l36.691-7.062c2.258-0.435,4.191-1.881,5.246-3.924l11.01-21.327l72.177-1.728l25.599,47.892    H147.654L154.269,346.297z M61.005,254.649c0-5.79,4.71-10.5,10.5-10.5s10.5,4.71,10.5,10.5v105.5h-21V254.649z"/>
//       <path style={{fill:'#333333'}} d="M446.979,388.647H7.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h439.479    c4.143,0,7.5-3.358,7.5-7.5S451.121,388.647,446.979,388.647z"/>
//       <path style={{fill:'#333333'}} d="M164.981,166.326c31.842,0,57.747-25.905,57.747-57.747s-25.905-57.748-57.747-57.748    c-31.843,0-57.748,25.906-57.748,57.748S133.139,166.326,164.981,166.326z M164.981,65.831c23.571,0,42.747,19.177,42.747,42.748    s-19.176,42.747-42.747,42.747s-42.748-19.176-42.748-42.747S141.41,65.831,164.981,65.831z"/>
//     </g>
//   </g>
//   </svg>
// )

export default ({style}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 439.48 439.48"
    style={style}
    xmlSpace="preserve"
    width="512"
    height="512"
  >
    <g>
      <polygon style={{fill:'#CCCCCC'}} points="362.003,370.136 129.003,370.136 129.003,368.576 141.633,332.276 182.043,324.496    186.853,315.186 195.093,299.216 276.333,297.276 289.163,321.286 343.003,324.276  "/>
      <polygon style={{fill:'#999999'}} points="362.003,370.136 343.003,324.276 289.163,321.286 299.979,346.637 254.41,354.136    303.393,354.641 310.003,370.136  "/>
      <path style={{fill:'#07A521'}} d="M116.503,245.606v16.17c0,12.86-10.46,23.33-23.33,23.33h-11.17v86.03h-36v-56.03h-11.17   c-12.86,0-23.33-10.47-23.33-23.33v-16.17c0-0.73,0.07-1.43,0.21-2.12c0,0,0,0,0-0.01c0.99-4.78,5.22-8.37,10.29-8.37   c5.8,0,10.5,4.7,10.5,10.5v16.17c0,1.28,1.05,2.33,2.33,2.33h11.17v-49.5c0-9.94,8.06-18,18-18s18,8.06,18,18v19.5h11.17   c1.29,0,2.33-1.05,2.33-2.33v-16.17c0-0.73,0.07-1.43,0.21-2.12c0,0,0,0,0-0.01c0.99-4.78,5.22-8.37,10.29-8.37   C111.803,235.106,116.503,239.806,116.503,245.606z"/>
      <path style={{fill:'#0FBC30'}} d="M405.006,313.106c-4.143,0-7.5,3.358-7.5,7.5v0.418c0,1.671-1.339,3.029-3,3.074v-13.492   c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v61.53c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-33.034   c9.933-0.045,18-8.135,18-18.078v-0.418C412.506,316.464,409.148,313.106,405.006,313.106z"/>
      <circle style={{fill:'#EAB932'}} cx="157.482" cy="101.204" r="50.25"/>
      <g>
        <path style={{fill:'#FFD039'}} d="M439.48,388.526H0c0-4.43,1.13-8.59,3.12-12.22c1.17-2.14,2.64-4.09,4.35-5.8    c4.61-4.62,10.99-7.47,18.03-7.47h388.48c9.65,0,18.05,5.36,22.38,13.27C438.35,379.936,439.48,384.096,439.48,388.526z"/>
        <path style={{fill:'#EAB932'}} d="M439.48,388.526H0c0-4.43,1.13-8.59,3.12-12.22h433.24    C438.35,379.936,439.48,384.096,439.48,388.526z"/>
      </g>
      <path style={{fill:'#FFD039'}} d="M207.732,101.214c0,27.75-22.5,50.24-50.25,50.24c-4.47,0-8.81-0.58-12.94-1.68   c-8.21-7.91-13.31-19.01-13.31-31.31c0-24.03,19.48-43.5,43.5-43.5c12.3,0,23.4,5.1,31.32,13.31   C207.152,92.404,207.732,96.744,207.732,101.214z"/>
      <polygon style={{fill:'#999999'}} points="218.413,318.806 182.043,324.496 186.853,315.186  "/>
      <path style={{fill:'#0FBC30'}} d="M116.503,245.606v5.94c-1.52,0.38-3.12,0.59-4.76,0.59c-6.7,0-12.6-3.44-16.03-8.65c0,0,0,0,0-0.01   c0.99-4.78,5.22-8.37,10.29-8.37C111.803,235.106,116.503,239.806,116.503,245.606z"/>
      <path style={{fill:'#0FBC30'}} d="M32.503,275.606v5.94c-1.52,0.38-3.12,0.59-4.76,0.59c-6.7,0-12.6-3.44-16.03-8.65c0,0,0,0,0-0.01   c0.99-4.78,5.22-8.37,10.29-8.37C27.803,265.106,32.503,269.806,32.503,275.606z"/>
      <path style={{fill:'#0FBC30'}} d="M46.903,262.966c0,2.03-0.31,3.98-0.9,5.82v-11.64C46.593,258.986,46.903,260.936,46.903,262.966z"/>
      <path style={{fill:'#0FBC30'}} d="M82.003,244.606v9.08c-4,2.4-8.68,3.78-13.69,3.78c-9.33,0-17.55-4.79-22.31-12.05v-0.81   c0-9.94,8.06-18,18-18S82.003,234.666,82.003,244.606z"/>
    </g>
  </svg>
)
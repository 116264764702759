import React, {useState, useEffect} from 'react'
import { VictoryPie, VictoryTooltip } from 'victory'
import { Form, Formik, Field, useField } from 'formik'
import CountUp from 'react-countup'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import LocationIcon from '@material-ui/icons/Room'
import Avatar from '@material-ui/core/Avatar'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ArticlesIcon from '@material-ui/icons/Assignment'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

import ReactSimpleSelect from './components/fields/ReactSimpleSelect'

import { dataService } from './services'

const StockBalance = () => {

  useEffect(() => {
    dataService.getAll({ model: 'articles', params: '?pagination=off'})
      .then(data => {
        const { result } = data
        const tmpOptions = []
        result.forEach(option => {
          tmpOptions.push({ value: option.id, label: `${option.article_number}: ${option.label}`})
        })
        setOptions(tmpOptions)
      })
  }, [])

  useEffect(() => {
    setLoading(true)
    dataService.getAll({ model: 'storages', params: '?pagination=off'})
      .then(data => {
        const { result } = data
        const promises = []
        result.forEach(storage => {
          const promise = dataService.getAll({ model: 'article_items', params: `?filter[0][key]=storage_id&filter[0][value]=${storage.id}`})
          promises.push(promise)
        })
        let total = 0
        Promise.all(promises).then(data => {
          data.forEach(storage => {
            total += storage.total
            const object = result.find(store => store.id === (storage.result[0] && storage.result[0].storage_id))
            if (object) {
              object.balance = storage.total
            }
          })
          setStorages(result)
          setTotal(total)
          setLoading(false)
        })
      })
  }, [])


  const getArticle = id => {
    const promises = []
    const tmpStorages = []
    let totalAmount = 0
    storages.forEach(storage => {
      const promise = dataService.getAll({ model: 'article_items', params: `?filter[0][key]=article_id&filter[0][value]=${id}&filter[1][key]=storage_id&filter[1][value]=${storage.id}`})
      tmpStorages.push({ id: storage.id, label: storage.label, balance: 0})
      promises.push(promise)
    })
    Promise.all(promises).then(data => {
      if (data && data.length > 0) {
        data.forEach(storage => {
          const { total, result } = storage
          totalAmount += total
          if (result && result.length > 0 && result[0]) {
            const tmpStorage = tmpStorages.find(tmp => tmp.id === result[0].storage_id)
            if (tmpStorage) {
              tmpStorage.balance = total
            } 
          }
        })
      }
      setSelectedStorages(tmpStorages)
      setSelectedTotal(totalAmount)
    })
  }

  const [selectedStorages, setSelectedStorages] = useState([])
  const [selectedTotal, setSelectedTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [storages, setStorages] = useState([])
  const [options, setOptions] = useState([])
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} xl={4}>
        <Paper>
          <Toolbar>
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <Typography variant='subtitle2' color='textSecondary'>Artiklar</Typography>
            <Typography variant='h5'><CountUp end={total} separator=' ' /></Typography>
            </div>
            <Avatar style={{marginLeft: 'auto', backgroundColor: '#1a76d0'}}>
              <ArticlesIcon />
            </Avatar>
          </Toolbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Lagerplats
                </TableCell>
                <TableCell>
                  Antal
                </TableCell>
                <TableCell align='right'>
                  %
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading ? (
                <React.Fragment>
                  {storages.map(storage => (
                    <TableRow key={storage.id}>
                      <TableCell>
                        {storage.label}
                      </TableCell>
                      <TableCell>
                        {storage.balance ? storage.balance : 0}
                      </TableCell>
                      <TableCell align='right'>
                        <div
                          style={{
                            position: 'relative',
                            width: '150px',
                            height: 16,
                            backgroundColor: '#f9f9fc',
                            borderRadius: 3,
                            marginLeft: 'auto'
                          }}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              bottom: 0,
                              left: 0,
                              width: `${storage.balance ? storage.balance / total * 100 : 0}%`,
                              minWidth: storage.balance ? 3 : 0,
                              backgroundColor: '#1a76d0',
                              borderTopLeftRadius: 3,
                              borderBottomLeftRadius: 3
                            }}>

                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {[...Array(5)].map((e, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Skeleton variant='rect' />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant='rect' />
                      </TableCell>
                      <TableCell align='right'>
                        <Skeleton variant='rect' />
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <Paper>
          <Toolbar>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                paddingRight: 10
              }}
            >
              <ReactSimpleSelect
                options={options}
                onChange={option => getArticle(option.value)}
                dense
                placeholder={`Välj artikel`}
                label={'Artikel'}
              />
            </div>
            <Avatar style={{marginLeft: 'auto', backgroundColor: '#d0911a'}}>
              <LocationIcon />
            </Avatar>
          </Toolbar>
          {selectedStorages.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Lagerplats
                  </TableCell>
                  <TableCell>
                    Antal
                  </TableCell>
                  <TableCell align='right'>
                    %
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedStorages.map(storage => (
                  <TableRow>
                    <TableCell>
                      {storage.label}
                    </TableCell>
                    <TableCell>
                      {storage.balance ? storage.balance : 0}
                    </TableCell>
                    <TableCell align='right'>
                      <div
                        style={{
                          position: 'relative',
                          width: '150px',
                          height: 16,
                          backgroundColor: '#f9f9fc',
                          borderRadius: 3,
                          marginLeft: 'auto'
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            transition: 'all 1s ease-in-out',
                            width: `${storage.balance ? storage.balance / selectedTotal * 100 : 0}%`,
                            minWidth: storage.balance ? 3: 0,
                            backgroundColor: '#d0911a',
                            borderTopLeftRadius: 3,
                            borderBottomLeftRadius: 3
                          }}>

                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default StockBalance
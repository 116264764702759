import React from 'react'
import MuiTextfield from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const PasswordField = ({
  field: { ...fields},
  form: { touched, errors, ...rest},
  ...props
}) => {
  const [show, setShow] = React.useState(false)

  const handleClick = () => {
    setShow(!show)
  }

  return (
    <MuiTextfield
      {...props}
      {...fields}
      type={!show ? 'password' : 'text'}
      error={Boolean(touched[fields.name] && errors[fields.name])}
      helperText={touched[fields.name] && errors[fields.name]}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              onClick={handleClick}
              tabIndex={-1}
            >
              {!show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

export default PasswordField


import React from 'react'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import NotificationIcon from '@material-ui/icons/NotificationImportant'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import WarningIcon from '@material-ui/icons/Warning'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white
  }
}))

export default function MenuListComposition() {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  function handleToggle() {
    setOpen(prevOpen => !prevOpen)
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <div>
        <Tooltip title='Varningar' aria-label='notifications'>
          <IconButton
            ref={anchorRef}
            aria-label='show NotificationImportant'
            color='inherit'
            aria-controls="notification-list"
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <Badge badgeContent={3} color="error">
              <NotificationIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Popper
          open={open}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport',
            }
          }}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          placement='bottom'
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Paper id="menu-list-grow">
                  <Toolbar>
                    <Typography component='h6' variant='subtitle1'>
                      Varningar
                    </Typography>
                  </Toolbar>
                  <Divider />
                  <MenuList>
                    <MenuItem>
                      <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                          <WarningIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Lågt lagervärde (5st) på 1603" secondary="Sep 9, 2019" />
                    </MenuItem>
                    <MenuItem>
                      <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                          <WarningIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Lågt lagervärde (5st) på 1603" secondary="Sep 9, 2019" />
                    </MenuItem>
                    <MenuItem>
                      <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                          <WarningIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Lågt lagervärde (5st) på 1603" secondary="Sep 9, 2019" />
                    </MenuItem>
                  </MenuList>
                </Paper>
              </ClickAwayListener>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  )
}